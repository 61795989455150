import React, { Component } from "react";
import { TICKETINGDEALS, DELETE_ICON } from "../../../../../assets/index";
import LoadingSpinner from "../../../../LoadingSpinner";
import AddDeal from "../../AddDeal";
import EditDeal from "../../EditDeal";
import * as API from "../../../../../configuration/apiconfig";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export default class TicketingDeals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
      showModal: false,
      showEditModal: false,
      dealType: "ticket",
      isLoading: false,
      selectedDealForEdit: {},
      selectedTicketingDeal: {},
    };
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.enableDeals !== this.props.enableDeals) {
      this.setState({
        // isChecked: !this.state.isChecked,
        selectedTicketingDeal: this.props.enableDeals,
      });
    }
  };

  handleSwitchToggle = async () => {
    this.setState({
      // isChecked: !this.state.isChecked,
      selectedTicketingDeal: {
        ...this.state.selectedTicketingDeal,
        enableTicketingDeals:
          !this.state.selectedTicketingDeal.enableTicketingDeals,
      },
    });

    await API.callEndpoint(
      "PATCH",
      "Bearer",
      `/api/companies/${this.props.selectedCompany.id}/tiers`,
      {
        id: this.state.selectedTicketingDeal.id,
        enableTicketingDeals:
          !this.state.selectedTicketingDeal.enableTicketingDeals,
      }
    ).then((response) => {
      return response.data;
    });

    // Call deal api on toggle
    this.props.handleUpdatedDeals();
    await this.props.handleEnableTicketingDeals();
  };

  handleAddModalOpen = () => {
    this.setState({ showModal: true });
  };

  handleAddModalClose = () => {
    this.props.handleUpdatedDeals();
    this.setState({ showModal: false });
  };

  handleEditModalOpen = (dealObj) => {
    this.setState({
      selectedDealForEdit: dealObj,
    });
    this.setState({ showEditModal: true });
  };

  handleEditModalClose = () => {
    this.props.handleUpdatedDeals();
    this.setState({ showEditModal: false });
  };

  handleDeleteDeal = (dealObj) => {
    API.callEndpoint(
      "DELETE",
      "Bearer",
      `/api/companies/${this.props.selectedCompany.id}/tiers/${this.props.tierId}/deals/${dealObj.id}`
    ).then((response) => {
      Swal.fire({
        icon: "success",
        title: "Deal deleted successfully",
        showConfirmButton: true,
        confirmButtonColor: "#e51937",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.props.handleUpdatedDeals();
        }
      });
      return response.data;
    });
  };

  render() {
    const { isChecked, isLoading } = this.state;
    return (
      <section className="deals-card">
        <div className="deal">
          <div className="deals-details d-flex">
            <div className="deal-image">
              <img src={TICKETINGDEALS} alt="ticketing-icon" />
            </div>
            <div className="deal-content flex-grow-1">
              <div>
                <h2 className="title">Ticketing Deals</h2>
                <p className="content">
                  To configure the Ticketing Deals for the{" "}
                  <span className="bold-text">{this.props.tierName} </span>
                  for{" "}
                  <span className="bold-text">
                    {this.props.selectedCompany.companyName}
                  </span>
                </p>
                <p className="content info">
                  Use the toggle switch to the right
                </p>
              </div>
            </div>
            <div className="deal-button">
              <div
                className={`toggle ${
                  this.state.selectedTicketingDeal.enableTicketingDeals
                    ? "toggle-on"
                    : "toggle-off"
                }`}
              >
                <span className="on-switch" onClick={this.handleSwitchToggle}>
                  ON
                </span>
                <input
                  type="checkbox"
                  id="toggle"
                  checked={
                    this.state.selectedTicketingDeal.enableTicketingDeals
                  }
                />
                <label for="toggle" className="toggle-label"></label>
                <span className="off-switch" onClick={this.handleSwitchToggle}>
                  OFF
                </span>
              </div>
              {this.state.selectedTicketingDeal.enableTicketingDeals ? (
                <a
                  className="create-deal-link"
                  onClick={this.handleAddModalOpen}
                >
                  Create Ticketing Deal
                </a>
              ) : (
                ""
              )}
            </div>
          </div>

          {this.state.selectedTicketingDeal.enableTicketingDeals &&
          this.props.ticketingDeals.length > 0 ? (
            <div className="card card-body">
              <div className="row row-cols-2">
                {this.props.ticketingDeals.map((deal) => {
                  return (
                    <div className="deal-row" key={deal.id}>
                      <div className="left d-flex align-items-center">
                        <h3 className="title me-3">{deal.name}</h3>
                        <button
                          className="edit-deal"
                          onClick={() => this.handleEditModalOpen(deal)}
                        >
                          <span className="icon-edit"></span>
                        </button>

                        <button
                          className="delete-deal"
                          onClick={() => this.handleDeleteDeal(deal)}
                        >
                          <span className="icon-delete"></span>
                        </button>
                      </div>
                      <div>
                        <p className="sar-info">{deal.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}

          <AddDeal
            showModal={this.state.showModal}
            handleOpen={this.handleAddModalOpen}
            handleClose={this.handleAddModalClose}
            companyId={this.props.selectedCompany.id}
            tierId={this.props.tierId}
            dealType="ticket"
          />

          <EditDeal
            showEditModal={this.state.showEditModal}
            handleOpen={this.handleEditModalOpen}
            handleClose={this.handleEditModalClose}
            selectedDeal={this.state.selectedDealForEdit}
            companyId={this.props.selectedCompany.id}
            tierId={this.props.tierId}
          />
        </div>

        {isLoading ? (
          <div className="loader-div">
            {" "}
            <LoadingSpinner />{" "}
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}
