import React, { Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import * as API from "../../../../configuration/apiconfig";
import Heading from "../../Heading";
import LoadingSpinner from "../../../LoadingSpinner";
import { WARNING_ICON } from "../../../../assets/index";
class AddCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isChecked: true,
      file: "",
      uploadedFileName: "",
      // imageUploadError: "",
      uploadedImageUrl: "",
      companyData: {
        companyName: "",
        arabicCompanyName: "",
        contactName: "",
        contactEmail: "",
        emailHandle: "",
        location: "",
        status: 1,
      },
      errors: {
        companyName: "",
        arabicCompanyName: "",
        contactName: "",
        contactEmail: "",
        emailHandle: "",
        location: "",
      },
    };
  }

  validate = (name, value) => {
    const { companyData } = this.state;
    switch (name) {
      case "companyName":
        if (!value || value.trim() === "") {
          return "Please enter the company name.";
        } else {
          return "";
        }
      case "arabicCompanyName":
        if (!value || value.trim() === "") {
          return "Please enter the company name in Arabic";
        } else {
          return "";
        }
      case "contactName":
        if (!value || value.trim() === "") {
          return "Please enter the contact name.";
        } else {
          return "";
        }
      case "contactEmail":
        if (!value) {
          return "Please enter the contact email address.";
        } else if (
          !value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
        ) {
          return "Please enter a valid contact email address";
        } else {
          return "";
        }
      case "emailHandle":
        if (!value) {
          return " Please enter the company email address.";
        }
        // else if (
        //   !value.match(/^@([a-zA-Z0-9-]+[.]){1,2}[a-zA-Z]{2,10}$/)
        // ) {
        //   return "Please enter a valid company email address";
        // }
        else {
          return "";
        }
      case "location":
        if (!value || value.trim() === "") {
          return "Please enter the location.";
        } else {
          return "";
        }
      default: {
        return "";
      }
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: this.validate(name, value),
      },
      companyData: {
        ...this.state.companyData,
        [name]: value,
      },
    });
  };

  handleChangeSwitch = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  handleImageUpload = async (e) => {
    if (e.target.files.length > 0) {
      let filename = e.target.files[0].name;
      this.setState({
        uploadedFileName: filename,
        file: e.target.files[0],
        // imageUploadError: "",
      });
    }

    await this.handleImageUploadApiCall(e.target.files[0]);
  };

  handleImageUploadApiCall = async (file) => {
    const responseData = await API.uploadImage("POST", file, "logo")
      .then((response) => {
        this.setState({
          uploadedImageUrl: response.url,
        });
        return response.url;
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
        Swal.fire({
          icon: "error",
          title: "Error uploading logo, Please try again later",
          showConfirmButton: true,
          confirmButtonColor: "#e51937",
        }).then((result) => {
          if (result.isConfirmed) {
            this.setState({
              file: "",
              uploadedFileName: "",
            });
          }
        });
      });
  };

  handleAddCompanyApiCall = async () => {
    const { companyData } = this.state;
    let emailHandleString = this.state.companyData.emailHandle;
    let emailHandleArray = emailHandleString.split(",").map((i) => i.trim());
    const data = {
      companyName: companyData.companyName,
      altName: companyData.arabicCompanyName,
      contactName: companyData.contactName,
      contactEmail: companyData.contactEmail,
      location: companyData.location,
      emailHandle: emailHandleArray,
      status: this.state.isChecked ? 1 : 0,
      logo: this.state.uploadedImageUrl,
      phone: "7382451005",
      expires: "2023-10-11",
    };

    this.setState({
      isLoading: true,
    });

    API.callEndpoint("POST", "Bearer", "/api/companies", data)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        Swal.fire({
          icon: "success",
          title: "Company added successfully",
          showConfirmButton: true,
          confirmButtonColor: "#e51937",
          allowOutsideClick: false,
        })
          .then((result) => {
            if (result.isConfirmed) {
              this.props.navigate("/manage-companies");
            }
          })
          .catch((error) => {
            console.log(error);
          });

        return response.data;
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        Swal.fire({
          icon: "error",
          title: error.error,
          showConfirmButton: true,
          confirmButtonColor: "#e51937",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.props.navigate("/manage-companies");
          }
        });
      });
  };

  handleSubmit = async (e) => {
    const { companyData } = this.state;
    e.preventDefault();
    let validationErrors = {};

    // if (this.state.uploadedFileName === "") {
    //   this.setState({
    //     imageUploadError: "Please upload company logo.",
    //   });
    // }
    Object.keys(companyData).forEach((name) => {
      const error = this.validate(name, companyData[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      this.setState({ errors: validationErrors });
      return;
    } else {
      await this.handleAddCompanyApiCall();
    }

    // if (
    //   companyData.companyName &&
    //   companyData.arabicCompanyName &&
    //   companyData.contactName &&
    //   companyData.contactEmail &&
    //   companyData.emailHandle &&
    //   companyData.location
    //   // this.state.file
    // ) {
    //   console.log("hello")
    // this.setState({
    //   isLoading: true,
    // });
    // await this.handleAddCompanyApiCall();
    // }
    // this.setState({
    //   isLoading: false,
    // });
    //   // const responseData = API.uploadImage(this.state.file, "logo")
    //   //   .then((response) => {
    //   //     const data = {
    //   //       companyName: companyData.companyName,
    //   //       altName: companyData.arabicCompanyName,
    //   //       contactName: companyData.contactName,
    //   //       contactEmail: companyData.contactEmail,
    //   //       location: companyData.location,
    //   //       emailHandle: emailHandleArray,
    //   //       status: this.state.isChecked ? 1 : 0,
    //   //       logo: response.url,
    //   //       phone: "7382451005",
    //   //       expires: "2023-10-11",
    //   //     };
    //   //     this.setState({
    //   //       isLoading: true,
    //   //     });
    //   //     API.callEndpoint("POST", "Bearer", "/api/companies", data).then(
    //   //       (response) => {
    //   //         Swal.fire({
    //   //           icon: "success",
    //   //           title: "Company added successfully",
    //   //           showConfirmButton: true,
    //   //           confirmButtonColor: "#e51937",
    //   //           allowOutsideClick: false,
    //   //         }).then((result) => {
    //   //           if (result.isConfirmed) {
    //   //             this.props.navigate("/manage-companies");
    //   //           }
    //   //         });
    //   //         return response.data;
    //   //       }
    //   //     );
    //   //     this.setState({
    //   //       isLoading: false,
    //   //     });
    //   //   })
    //   //   .catch(() => {
    //   //     this.setState({
    //   //       isLoading: false,
    //   //     });
    //   //     Swal.fire({
    //   //       icon: "error",
    //   //       title: "Error uploading logo, Please try again later",
    //   //       showConfirmButton: true,
    //   //       confirmButtonColor: "#e51937",
    //   //     }).then((result) => {
    //   //       if (result.isConfirmed) {
    //   //         this.setState({
    //   //           file: "",
    //   //           uploadedFileName: "",
    //   //         });
    //   //       }
    //   //     });
    //   //   });
    // }
  };

  render() {
    const {
      errors,
      isLoading,
      isChecked,
      uploadedFileName,
      file,
      // imageUploadError,
    } = this.state;

    return (
      <section className="addCompanyWrapper">
        <Heading title="Adding New company" />

        <nav>
          <ul>
            <li>
              <a
                className="link-added"
                onClick={() => {
                  this.props.navigate("/dashboard");
                }}
              >
                Home
              </a>
            </li>
            <li>
              <a
                className="link-added"
                onClick={() => {
                  this.props.navigate("/manage-companies");
                }}
              >
                Manage Company
              </a>
            </li>
            <li>
              <a className="link-added">Add Company</a>
            </li>
          </ul>
        </nav>

        <div className="company-input-form">
          <a
            className="back-link"
            onClick={() => {
              this.props.navigate("/manage-companies");
            }}
          >
            Back
          </a>
          <div className="row">
            <div className="col-5 mb-5">
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control ${
                    errors.companyName ? "input-error" : ""
                  }`}
                  id="companyname"
                  placeholder="Company Name*"
                  name="companyName"
                  value={this.state.companyData.companyName}
                  onChange={this.handleChange}
                />
                <label htmlFor="companyname">Company Name*</label>
              </div>
              {errors.companyName && (
                <div className="error-text d-flex">
                  <img src={WARNING_ICON} alt="warning" className="me-1" />
                  <span>{errors.companyName}</span>
                </div>
              )}
            </div>

            <div className="col-5 mb-5">
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control ${
                    errors.arabicCompanyName ? "input-error" : ""
                  }`}
                  id="arabicCompanyName"
                  placeholder="Company Name (Arabic)*"
                  name="arabicCompanyName"
                  value={this.state.companyData.arabicCompanyName}
                  onChange={this.handleChange}
                />
                <label htmlFor="companyname">Company Name (Arabic)*</label>
              </div>
              {errors.arabicCompanyName && (
                <div className="error-text d-flex">
                  <img src={WARNING_ICON} alt="warning" className="me-1" />
                  <span>{errors.arabicCompanyName}</span>
                </div>
              )}
            </div>

            <div className="col-5 mb-5">
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control ${
                    errors.location ? "input-error" : ""
                  }`}
                  id="location"
                  placeholder="Location*"
                  name="location"
                  value={this.state.companyData.location}
                  onChange={this.handleChange}
                />
                <label htmlFor="location">Location*</label>
              </div>
              {errors.location && (
                <div className="error-text d-flex">
                  <img src={WARNING_ICON} alt="warning" className="me-1" />
                  <span>{errors.location}</span>
                </div>
              )}
            </div>

            <div className="col-5 mb-5">
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control ${
                    errors.contactName ? "input-error" : ""
                  }`}
                  id="contactname"
                  placeholder="Contact Name"
                  name="contactName"
                  value={this.state.companyData.contactName}
                  onChange={this.handleChange}
                />
                <label htmlFor="contactname">Contact Name*</label>
              </div>
              {errors.contactName && (
                <div className="error-text d-flex">
                  <img src={WARNING_ICON} alt="warning" className="me-1" />
                  <span>{errors.contactName}</span>
                </div>
              )}
            </div>

            <div className="col-5 mb-5">
              <div className="form-floating">
                <input
                  type="email"
                  className={`form-control ${
                    errors.contactEmail ? "input-error" : ""
                  }`}
                  id="contactemail"
                  placeholder="Contact Email address"
                  name="contactEmail"
                  value={this.state.companyData.contactEmail}
                  onChange={this.handleChange}
                />
                <label htmlFor="contactemail">Contact Email address*</label>
              </div>
              {errors.contactEmail && (
                <div className="error-text d-flex">
                  <img src={WARNING_ICON} alt="warning" className="me-1" />
                  <span>{errors.contactEmail}</span>
                </div>
              )}
            </div>

            <div className="col-5 mb-5">
              <div className="form-floating">
                <input
                  type="email"
                  className={`form-control ${
                    errors.emailHandle ? "input-error" : ""
                  }`}
                  // className="form-control"
                  id="emailHandle"
                  placeholder="Company Email Domain"
                  name="emailHandle"
                  value={this.state.companyData.emailHandle}
                  onChange={this.handleChange}
                />
                <label htmlFor="emailHandle">Company Email Domain*</label>
              </div>
              {errors.emailHandle && (
                <div className="error-text d-flex">
                  <img src={WARNING_ICON} alt="warning" className="me-1" />
                  <span>{errors.emailHandle}</span>
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-5 mb-5">
              <div className="form-check form-switch">
                <label className="form-check-label">Status</label>
                <div className="toggle-activate">
                  <label
                    className="form-label"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    Inactive
                  </label>
                  <input
                    className={`form-check-input ${isChecked ? "checked" : ""}`}
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    onChange={this.handleChangeSwitch}
                    checked={isChecked}
                  />
                  <label
                    className="form-label"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    Active
                  </label>
                </div>
              </div>
            </div>
            <div className="col-5 mb-5">
              <label className="form-label">Upload company logo</label>

              {uploadedFileName ? (
                <div className="upload filename">{uploadedFileName}</div>
              ) : (
                <div
                  // className={`upload ${imageUploadError ? "input-error" : ""}`}
                  className="upload"
                >
                  Upload company logo
                  <input
                    type="file"
                    className="form-control"
                    id="uploadlogo"
                    accept="image/png, image/gif, image/jpeg, image/jpg, image/svg"
                    onChange={this.handleImageUpload}
                  />
                </div>
              )}
              {/* {imageUploadError && (
                <div className="error-text d-flex">
                  <img src={WARNING_ICON} alt="warning" className="me-1" />
                  <span>{imageUploadError}</span>
                </div>
              )} */}
            </div>
          </div>

          <div className="btnWrap">
            <button
              className="btn btn-primary btn-small"
              onClick={this.handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>

        {isLoading ? (
          <div className="loader-div">
            {" "}
            <LoadingSpinner />{" "}
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}

export default function TempFunction(props) {
  return <AddCompany navigate={useNavigate()} />;
}
