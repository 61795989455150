import React, { Component } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { CAPPING, CROSS_ICON } from "../../../../../assets/index";
import * as API from "../../../../../configuration/apiconfig";
import LoadingSpinner from "../../../../LoadingSpinner";

const hoursList = ["0", 1, 2, 3, 4, 5, 6, 7, 8];

export default class CappingDeals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: true,
      isLoading: false,
      selectedCompany: this.props.selectedCompany,
      selectedTier: this.props.ticketCounts,
      errors: {
        tksAllowedPerTransaction: "",
        bksAllowedPerDay: "",
        tktsAllowedPerMonth: "",
      },
      isSelectedAllChecked: false,
      apiCinemasList: [],
      filteredCinemaList: [],
      selectedCinema: [],
      includedCinemaIds: [],
      cinemaRulesList: [],

      films: [],
      // filteredMovieList: filteredMovieList,
      selectedMovies: [],
      movieSearch: "",
      rule: [],
      movieRulesList: [],
      hour: null,
    };
  }

  getCinemas = async () => {
    const getCinemas = await API.callEndpoint(
      "GET",
      "Bearer",
      "/api/cinemas"
    ).then((response) => {
      return response.data;
    });

    this.setState({
      apiCinemasList: getCinemas,
      selectedCinema: getCinemas,
      filteredCinemaList: getCinemas,
    });
  };

  getFilms = async () => {
    const filmData = await API.callEndpoint("GET", "Bearer", "/api/films").then(
      (response) => {
        return response.data;
      }
    );

    this.setState(
      {
        films: filmData,
      },
      () => {
        this.state.films.map((film) => (film.isChecked = false));
      }
    );
  };

  // handleGetCinemaRules = async () => {
  //   const apiGetCinemaRules = await API.callEndpoint(
  //     "GET",
  //     "Bearer",
  //     `/api/companies/${this.props.selectedCompany.id}/tiers/${this.props.ticketCounts.id}/cinemarules`
  //   ).then((response) => {
  //     return response.data;
  //   });
  //   this.setState({
  //     cinemaRulesList: apiGetCinemaRules,
  //   });

  //   this.state.selectedCinema.map(
  //     (cinema) =>
  //       (cinema.isChecked = apiGetCinemaRules[0].enabledCinemas.includes(
  //         cinema.id
  //       )
  //         ? true
  //         : false)
  //   );

  //   const includedId = this.state.selectedCinema
  //     .filter((cinema) => cinema.isChecked === true)
  //     .map((cinema) => cinema.id);

  //   this.setState({
  //     selectedCinema: [...this.state.selectedCinema],
  //     includedCinemaIds: includedId,
  //   });
  // };

  componentDidUpdate(prevProps) {
    if (prevProps.cinemaRulesList !== this.props.cinemaRulesList) {
      if (this.props.cinemaRulesList.length > 0) {
        this.state.selectedCinema.map(
          (cinema) =>
            (cinema.isChecked =
              this.props.cinemaRulesList[0].enabledCinemas.includes(cinema.id)
                ? true
                : false)
        );

        const includedId = this.state.selectedCinema
          .filter((cinema) => cinema.isChecked === true)
          .map((cinema) => cinema.id);

        this.setState({
          selectedCinema: [...this.state.selectedCinema],
          includedCinemaIds: includedId,
          cinemaRulesList: this.props.cinemaRulesList,
          isSelectedAllChecked: this.state.selectedCinema.every(
            (item) => item.isChecked === true
          ),
        });
      } else {
        this.state.selectedCinema.map((cinema) => (cinema.isChecked = false));

        this.setState({
          cinemaRulesList: [],
          selectedCinema: [...this.state.selectedCinema],
          isSelectedAllChecked: this.state.selectedCinema.every(
            (item) => item.isChecked === true
          ),
        });
      }
    }

    if (prevProps.movieRulesList !== this.props.movieRulesList) {
      if (this.props.movieRulesList.length === 0) {
        this.state.films.map((film) => (film.isChecked = false));

        this.setState({
          films: [...this.state.films],
        });
        console.log("length 0");
      } else {
        this.state.films.map(
          (film) =>
            (film.isChecked = this.props.movieRulesList[0].rule.includes(
              film.id
            )
              ? true
              : false)
        );

        this.setState({
          films: [...this.state.films],
        });

        console.log("length not 0");
      }
      // this.state.selectedMovies.map(
      //   (movie) =>
      //     (movie.isChecked = this.props.movieRules[0].rule.includes(movie.id)
      //       ? true
      //       : false)
      // );

      // const includedId = this.state.selectedMovies
      //   .filter((movie) => movie.isChecked === true)
      //   .map((movie) => movie.id);

      // this.setState({
      //   selectedMovies: [...this.state.selectedMovies],
      //   rule: includedId,
      //   movieRulesList: this.props.movieRules,
      // });
    }

    if (prevProps.tierName !== this.props.tierName) {
      console.log(
        "this.props.ticketCounts.bookingAllowedHours -> " +
          this.props.ticketCounts.bookingAllowedHours
      );
      this.setState({
        filteredCinemaList: this.state.apiCinemasList,
        hour:
          this.props.ticketCounts.bookingAllowedHours == null
            ? "zero"
            : this.props.ticketCounts.bookingAllowedHours,
      });
    }
  }

  componentDidMount = async () => {
    await this.getCinemas();
    await this.getFilms();

    this.state.selectedCinema.map((cinema) => (cinema.isChecked = true));
    this.state.filteredCinemaList.map((cinema) => (cinema.isChecked = false));
  };

  validate = (name, value) => {
    switch (name) {
      case "tksAllowedPerTransaction":
        if (!value) {
          return "Please enter No. of Tickets allowed in a transaction";
        }
        // else if (!value.match(/^\d+$/)) {
        //   return "Please enter a valid number";
        // }
        else {
          return "";
        }
      case "bksAllowedPerDay":
        if (!value) {
          return "Please enter No. of Bookings allowed for a day";
        }
        // else if (!value.match(/^\d+$/)) {
        //   return "Please enter a valid number";
        // }
        else {
          return "";
        }
      case "tktsAllowedPerMonth":
        if (!value) {
          return "Please enter No. of Tickets available for a month";
        }
        // else if (!value.match(/^\d+$/)) {
        //   return "Please enter a valid number";
        // }
        else {
          return "";
        }
      default: {
        return "";
      }
    }
  };

  handleCinemaSearch = (e) => {
    let inputSearch = e.target.value;
    const filteredList = this.state.apiCinemasList.filter((cinema) => {
      if (cinema.name.toLowerCase().includes(inputSearch.toLowerCase())) {
        return cinema;
      }
    });
    this.setState({
      // cinemaSearch: inputSearch,
      filteredCinemaList: filteredList,
    });
    this.props.cinemaSearchValue(inputSearch, this.state.filteredCinemaList);
    // setTimeout(() => {
    //   window.onscroll = () =>
    //     window.pageYOffset === 0 &&
    //     this.setState({
    //       filteredCinemaList: this.state.apiCinemasList,
    //     });
    // }, 1500);
  };

  // handleMovieSearch = (e) => {
  //   let inputSearch = e.target.value;

  //   const filteredList = this.state.moviesList.filter((movie) => {
  //     if (movie.title.toLowerCase().includes(inputSearch.toLowerCase())) {
  //       return movie;
  //     }
  //   });
  //   this.setState({
  //     movieSearch: inputSearch,
  //     // filteredMovieList: filteredList,
  //   });
  // };

  setMovies = (obj) => {
    this.setState({
      selectedMovies: [...this.state.selectedMovies, obj],
      rule: [...this.state.rule, obj.id],
    });
    if (this.state.selectedMovies.includes(obj)) {
      this.setState({
        selectedMovies: [...this.state.selectedMovies],
        rule: [...this.state.rule],
      });
    }
  };

  handleRemoveCinema = (row) => {
    this.state.selectedCinema.find(
      (cinema) => row.id === cinema.id
    ).isChecked = false;

    this.setState({
      isSelectedAllChecked: false,
    });
    const includedId = this.state.selectedCinema
      .filter((cinema) => cinema.isChecked === true)
      .map((cinema) => cinema.id);
    this.setState({
      selectedCinema: [...this.state.selectedCinema],
      includedCinemaIds: includedId,
    });
  };

  handleRemoveFilm = (row) => {
    this.state.films.find((film) => film.id === row.id).isChecked = false;

    this.setState({
      films: [...this.state.films],
    });
  };

  handleAddToIncludedCinemasList = (row) => {
    if (row.isChecked === false) {
      this.state.selectedCinema.find(
        (cinema) => row.id === cinema.id
      ).isChecked = true;
    } else {
      this.state.selectedCinema.find(
        (cinema) => row.id === cinema.id
      ).isChecked = false;
      this.setState({
        isSelectedAllChecked: false,
      });
    }

    const selectedAll = this.state.selectedCinema.every(
      (item) => item.isChecked === true
    );
    if (selectedAll === true) {
      this.setState({
        isSelectedAllChecked: true,
      });
    }

    const includedId = this.state.selectedCinema
      .filter((cinema) => cinema.isChecked === true)
      .map((cinema) => cinema.id);

    this.setState({
      selectedCinema: [...this.state.selectedCinema],
      includedCinemaIds: includedId,
    });
  };

  handleSelectAllCinema = () => {
    if (this.state.isSelectedAllChecked === false) {
      this.setState({
        isSelectedAllChecked: true,
      });
      this.state.selectedCinema.map((cinema) => (cinema.isChecked = true));
    } else {
      this.setState({
        isSelectedAllChecked: false,
      });
      this.state.selectedCinema.map((cinema) => (cinema.isChecked = false));
    }

    const includedId = this.state.selectedCinema
      .filter((cinema) => cinema.isChecked === true)
      .map((cinema) => cinema.id);

    this.setState({
      selectedCinema: [...this.state.selectedCinema],
      includedCinemaIds: includedId,
    });
  };

  handleAddToExcludedMovieList = (filmObj) => {
    if (filmObj.isChecked === false) {
      this.state.films.find((film) => film.id === filmObj.id).isChecked = true;
    } else {
      this.state.films.find((film) => film.id === filmObj.id).isChecked = false;
    }

    this.setState({
      films: [...this.state.films],
    });
  };

  handleSelectMovieToBeExcluded = () => {};

  handleSetHour = (val) => {
    this.setState(
      {
        hour: val,
      },
      () => {
        console.log(this.state.hour);
      }
    );
  };

  handleSubmit = async (e) => {
    const { ticketCounts } = this.props;
    e.preventDefault();
    let validationErrors = {};

    Object.keys(ticketCounts).forEach((name) => {
      const error = this.validate(name, ticketCounts[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      this.setState({ errors: validationErrors });
      return;
    }

    this.setState({
      isLoading: true,
    });

    if (
      ticketCounts.tksAllowedPerTransaction &&
      ticketCounts.bksAllowedPerDay &&
      ticketCounts.tktsAllowedPerMonth &&
      this.state.hour
    ) {
      await API.callEndpoint(
        "PATCH",
        "Bearer",
        `/api/companies/${this.props.selectedCompany.id}/tiers`,
        {
          id: this.props.tierId,
          tksAllowedPerTransaction:
            this.props.ticketCounts.tksAllowedPerTransaction,
          bksAllowedPerDay: this.props.ticketCounts.bksAllowedPerDay,
          tktsAllowedPerMonth: this.props.ticketCounts.tktsAllowedPerMonth,
          bookingAllowedHours:
            this.state.hour == "0" || this.state.hour == "zero"
              ? null
              : this.state.hour,
        }
      ).then(async (response) => {
        // cinemarules api call
        var cinemaRulesPayload;
        if (this.props.methodTypeForCinemaAndMovieRules == "POST") {
          cinemaRulesPayload = {
            enabledCinemas: this.state.includedCinemaIds,
          };
        } else {
          cinemaRulesPayload = {
            id: this.props.cinemaRulesList[0].id,
            enabledCinemas: this.state.includedCinemaIds,
          };
        }
        await API.callEndpoint(
          this.props.methodTypeForCinemaAndMovieRules,
          "Bearer",
          `/api/companies/${this.props.selectedCompany.id}/tiers/${this.props.tierId}/cinemarules`,
          cinemaRulesPayload
        ).then((response) => {
          return response;
        });

        // MovieRules api call
        var movieRulesPayload;
        if (this.props.methodTypeForCinemaAndMovieRules == "POST") {
          movieRulesPayload = [
            {
              ruleType: "exclude",
              rule: this.state.films
                .filter((film) => film.isChecked === true)
                .map((film) => film.id),
            },
          ];
        } else {
          movieRulesPayload = [
            {
              id: this.props.movieRulesList[0].id,
              ruleType: "exclude",
              rule: this.state.films
                .filter((film) => film.isChecked === true)
                .map((film) => film.id),
            },
          ];
        }

        await API.callEndpoint(
          this.props.methodTypeForCinemaAndMovieRules,
          "Bearer",
          `/api/companies/${this.props.selectedCompany.id}/tiers/${this.props.tierId}/movierules`,
          movieRulesPayload
        ).then((response) => {
          return response;
        });

        this.setState(
          {
            isLoading: false,
            errors: {
              tksAllowedPerTransaction: "",
              bksAllowedPerDay: "",
              tktsAllowedPerMonth: "",
            },
          },
          () => {
            this.props.handleMethodTypeForCinemaAndMovieRules(
              this.props.ticketCounts
            );
            this.props.updateTierDataOnSuccess(this.props.tierName);
          }
        );
        Swal.fire({
          icon: "success",
          title: "Capping updated successfully",
          showConfirmButton: true,
          confirmButtonColor: "#e51937",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.props.handleUpdateTier();
          }
        });
        return response.data;
      });
    }
  };

  render() {
    const {
      isLoading,
      cinemaError,
      selectedCinema,
      selectedMovies,
      cinemaSearch,
      includedCinemaIds,
      rule,
    } = this.state;

    const isVisible = this.state.selectedCinema.some(
      (item) => item.isChecked === true
    );

    const isEnabled =
      this.props.ticketCounts.tktsAllowedPerMonth &&
      this.props.ticketCounts.tksAllowedPerTransaction &&
      this.props.ticketCounts.bksAllowedPerDay &&
      isVisible == true &&
      this.state.hour;

    const isEveryCinemaChecked = this.state.selectedCinema.every(
      (item) => item.isChecked === true
    );

    const isAnyOfTheFilmsChecked = this.state.films.some(
      (film) => film.isChecked === true
    );

    return (
      <section className="deals-card">
        <div className="deal">
          <div className="deals-details d-flex">
            <div className="deal-image">
              <img src={CAPPING} alt="ticketing-icon" />
            </div>
            <div className="deal-content flex-grow-1">
              <div>
                <h2 className="title">Capping</h2>
                <p className="content">
                  To configure Capping Rules for{" "}
                  <span className="bold-text">{this.props.tierName} </span>
                  for{" "}
                  <span className="bold-text">
                    {this.props.selectedCompany.companyName}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="card card-body capping-body">
            <div className="row company-input-form capping-details">
              <div className="col-4">
                <div className="cappingfield align-items-center">
                  <label className="form-label small-label">
                    No. of Tickets available for a month *
                  </label>
                  <input
                    type="text"
                    id="tktsAllowedPerMonth"
                    name="tktsAllowedPerMonth"
                    placeholder="e.g. 300"
                    className="form-control"
                    value={this.props.ticketCounts.tktsAllowedPerMonth || ""}
                    onChange={this.props.handleTicketInfoValueChange}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="cappingfield align-items-center">
                  <label className="form-label small-label">
                    No. of Tickets allowed in a transaction *
                  </label>
                  <input
                    type="text"
                    id="tksAllowedPerTransaction"
                    name="tksAllowedPerTransaction"
                    placeholder="e.g. 100"
                    className="form-control"
                    value={
                      this.props.ticketCounts.tksAllowedPerTransaction || ""
                    }
                    onChange={this.props.handleTicketInfoValueChange}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="cappingfield align-items-center">
                  <label className="form-label small-label">
                    No. of Bookings allowed for a day *
                  </label>
                  <input
                    type="text"
                    id="bksAllowedPerDay"
                    name="bksAllowedPerDay"
                    placeholder="e.g. 5"
                    className="form-control"
                    value={this.props.ticketCounts.bksAllowedPerDay || ""}
                    onChange={this.props.handleTicketInfoValueChange}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-7">
                <div className="cinemas-section movies-section company-input-form hours-dorpdown">
                  <div className="dropdown">
                    <label
                      htmlFor="bookingHours"
                      className="title cinema-section-title"
                    >
                      Allow Users to book # hours from showtime
                    </label>
                    <button
                      htmlFor="bookingHours"
                      type="button"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="true"
                      aria-expanded="false"
                      className="btn btn-secondary dropdown-toggle"
                    >
                      {this.state.hour
                        ? this.state.hour == "zero"
                          ? 0
                          : this.state.hour
                        : "Choose hours"}
                    </button>

                    <ul className="dropdown-menu">
                      {hoursList.map((hour) => {
                        return (
                          <li>
                            <a className="dropdown-item">
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  id={"#hour" + hour}
                                  value={hour}
                                  checked={
                                    this.state.hour == hour ||
                                    (this.state.hour == "zero" && hour == 0)
                                      ? true
                                      : false
                                  }
                                  onChange={() => this.handleSetHour(hour)}
                                />
                                <label
                                  class="form-check-label"
                                  for={"#hour" + hour}
                                  className="w-100 hour-label"
                                >
                                  <span
                                    onClick={() => this.handleSetHour(hour)}
                                  >
                                    {hour}
                                  </span>
                                </label>
                              </div>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="cinemas-section company-input-form">
                  <div className="dropdown">
                    <label
                      htmlFor="locations"
                      className="title cinema-section-title"
                    >
                      Allow Deals to these locations *
                    </label>
                    <button
                      className={`btn btn-secondary dropdown-toggle ${
                        cinemaError ? "input-error" : ""
                      }`}
                      type="button"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                    >
                      Choose Cinemas
                    </button>
                    <div className="dummy">
                      <ul className="dropdown-menu">
                        <li className="input-wrap">
                          <input
                            type="text"
                            name="product-search"
                            id="product-search"
                            placeholder="Search Cinema"
                            onChange={this.handleCinemaSearch}
                            value={this.props.cinemaSearch}
                          />
                        </li>
                        <li>
                          <a className="dropdown-item">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id=""
                                checked={this.state.isSelectedAllChecked}
                                onChange={this.handleSelectAllCinema}
                              />
                              <span onClick={this.handleSelectAllCinema}>
                                Select All
                              </span>
                            </div>
                          </a>
                        </li>
                        {this.state.filteredCinemaList.map((cinema) => {
                          return (
                            <li>
                              <a className="dropdown-item">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={cinema.id}
                                    checked={cinema.isChecked}
                                    onChange={() =>
                                      this.handleAddToIncludedCinemasList(
                                        cinema
                                      )
                                    }
                                  />
                                  <span
                                    className="w-100"
                                    onClick={() =>
                                      this.handleAddToIncludedCinemasList(
                                        cinema
                                      )
                                    }
                                  >
                                    {cinema.name}
                                  </span>
                                </div>
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="cinemas-section movies-section company-input-form">
                  <div className="dropdown">
                    <label
                      htmlFor="movies"
                      className="title cinema-section-title"
                    >
                      Movies to be Excluded
                    </label>
                    <button
                      htmlFor="movies"
                      type="button"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                      // className={`btn btn-secondary dropdown-toggle ${
                      //   movieError ? "input-error" : ""
                      // }`}
                      className="btn btn-secondary dropdown-toggle"
                    >
                      Choose Movies
                    </button>
                    <ul className="dropdown-menu">
                      {this.state.films.map((film) => {
                        return (
                          <li>
                            <a className="dropdown-item">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={film.id}
                                  checked={film.isChecked}
                                  onChange={() =>
                                    this.handleAddToExcludedMovieList(film)
                                  }
                                />
                                <span
                                  className="w-100"
                                  onClick={() =>
                                    this.handleAddToExcludedMovieList(film)
                                  }
                                >
                                  {film.title}
                                </span>
                              </div>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {selectedCinema.length > 0 && (
              <div
                className={`row selected-section ${
                  includedCinemaIds.length == 0 ? "d-none" : ""
                }`}
              >
                {isVisible && (
                  <div className="col-2 pe-0">
                    <h3 className="title pt-2">Included Cinemas</h3>
                  </div>
                )}
                <div className="col-10">
                  <div className="selected-cinemas-badges">
                    <div className="badges-grp">
                      {this.state.selectedCinema
                        .filter((cinema) => cinema.isChecked === true)
                        .map((cinema) => {
                          return (
                            <div className="badge d-flex">
                              <div className="me-4">{cinema.name}</div>
                              <div
                                onClick={() => this.handleRemoveCinema(cinema)}
                              >
                                <img
                                  src={CROSS_ICON}
                                  alt="cross-icon"
                                  className="cross-icon"
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isAnyOfTheFilmsChecked && (
              <div className="row selected-section">
                <div className="col-2">
                  <h3 className="title pt-2">Excluded Movies</h3>
                </div>
                <div className="col-10 pe-0">
                  <div className="selected-cinemas-badges">
                    <div className="badges-grp">
                      {this.state.films
                        .filter((film) => film.isChecked === true)
                        .map((film) => {
                          return (
                            <div className="badge">
                              <span className="me-4">{film.title}</span>
                              <span onClick={() => this.handleRemoveFilm(film)}>
                                <img
                                  src={CROSS_ICON}
                                  alt="cross-icon"
                                  className="cross-icon"
                                />
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="btnWrap center-button">
            <button
              className="btn btn-primary btn-small"
              onClick={this.handleSubmit}
              disabled={!isEnabled}
            >
              Submit
            </button>
          </div>
        </div>

        {isLoading ? (
          <div className="loader-div">
            {" "}
            <LoadingSpinner />{" "}
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}
