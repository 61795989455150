import React, { Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as API from "../../../configuration/apiconfig";
import Heading from "../Heading";
import LoadingSpinner from "../../LoadingSpinner";

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companiesList: [],
      filteredCompanies: [],
      search: "",
    };
  }

  componentDidMount = async () => {
    this.setState({
      isLoading: true,
    });
    const responseData = await API.callEndpoint(
      "GET",
      "Bearer",
      "/api/companies"
    ).then((response) => {
      return response.data;
    });
    this.setState({
      companiesList: responseData,
      filteredCompanies: responseData,
      isLoading: false,
    });
  };

  handleSearch = (e) => {
    let search = e.target.value;
    let tempRow = this.state.companiesList.filter((item) => {
      if (item.companyName.toLowerCase().includes(search.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    });

    this.setState({ search, filteredCompanies: tempRow });
  };

  render() {
    const { filteredCompanies, isLoading, search } = this.state;
    return (
      <section className="companiesWrapper">
        <Heading title="User Management" />
        <nav>
          <ul>
            <li>
              <a
                className="link-added"
                onClick={() => {
                  this.props.navigate("/dashboard");
                }}
              >
                Home
              </a>
            </li>
            <li>
              <a className="link-added">User Management</a>
            </li>
          </ul>
        </nav>
        <div className="btnWrap">
          <div className="search-box">
            <i class="bi bi-search"></i>
            <input
              type="text"
              placeholder="Search here..."
              onChange={this.handleSearch}
              value={search}
            />
          </div>
        </div>
        <div>
          <div className="table-responsive">
            <table className="manage-table table table-striped table-more-space">
              <thead className="table-dark">
                <tr>
                  <th>COMPANY NAME</th>
                  <th>NO.OF USERS</th>
                  <th></th>
                </tr>
              </thead>
              {filteredCompanies.length > 0 ? (
                <tbody>
                  {filteredCompanies.map((company) => {
                    return (
                      <tr>
                        <td className="font-gorita-medium">
                          <span>{company.companyName}</span>
                        </td>
                        <td>{company.usersCount}</td>

                        {company.tiersCount > 0 ? (
                          <td className="text-center">
                            <a
                              href="javascript:;"
                              className="link"
                              onClick={() => {
                                this.props.navigate("/manage-users/users", {
                                  state: company,
                                });
                              }}
                            >
                              View Users
                            </a>
                          </td>
                        ) : (
                          <td className="noTierMessage text-center">
                            No tiers created. Please create a tier first
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tr>
                  <td colSpan="3" className="text-center noData">
                    No company Found
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </table>
          </div>
        </div>
        {isLoading ? (
          <div className="loader-div">
            {" "}
            <LoadingSpinner />{" "}
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}

export default function TempFunction(props) {
  return <UserManagement navigate={useNavigate()} />;
}
