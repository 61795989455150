import moment from "moment";
import React, { Component } from "react";
import * as API from "../../../configuration/apiconfig";
import Heading from "../Heading";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../LoadingSpinner";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import "bootstrap-icons/font/bootstrap-icons.css";

class ManageCompanies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companiesList: [],
      selectedCompaniesforDelete: [],
      isLoading: false,
      isDisabled: true,
      search: "",
      filteredCompanies: [],
    };
  }

  getCompanies = async () => {
    this.setState({
      isLoading: true,
    });
    const responseData = await API.callEndpoint(
      "GET",
      "Bearer",
      "/api/companies"
    ).then((response) => {
      return response.data;
    });
    this.setState({
      companiesList: responseData,
      filteredCompanies: responseData,
      isLoading: false,
    });
  };

  componentDidMount = async () => {
    const apiResponseData = await this.getCompanies();
  };

  handleChecked = (row) => {
    this.setState({
      isDisabled: false,
      selectedCompaniesforDelete: [
        ...this.state.selectedCompaniesforDelete,
        row,
      ],
    });
    if (this.state.selectedCompaniesforDelete.includes(row)) {
      const index = this.state.selectedCompaniesforDelete.findIndex((obj) => {
        return obj.id === row.id;
      });
      this.state.selectedCompaniesforDelete.splice(index, 1);
      this.setState({
        selectedCompaniesforDelete: [...this.state.selectedCompaniesforDelete],
      });
    }
  };

  handleCompanyDelete = (obj) => {
    // const selectedObj = array.map((obj) => {
    //   return obj.id;
    // });
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to delete the company",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: "#e51937",
      cancelButtonColor: "#918d8d",
    }).then((result) => {
      if (result.isConfirmed) {
        API.callEndpoint("DELETE", "Bearer", `/api/companies/${obj.id}`).then(
          (response) => {
            Swal.fire({
              icon: "success",
              title: "Company deleted successfully.",
              showConfirmButton: true,
              confirmButtonColor: "#e51937",
            }).then((result) => {
              this.getCompanies();
              // this.setState({
              //   selectedCompaniesforDelete: [],
              // });
            });

            return response;
          }
        );
      }
    });
  };

  handleSearch = (e) => {
    let search = e.target.value;
    let tempRow = this.state.companiesList.filter((item) => {
      if (item.companyName.toLowerCase().includes(search.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    });

    this.setState({ search, filteredCompanies: tempRow });
  };

  render() {
    const {
      companiesList,
      isLoading,
      isDisabled,
      selectedCompaniesforDelete,
      filteredCompanies,
      search,
    } = this.state;

    return (
      <section className="companiesWrapper">
        <div className="top-bar">
          <div className="left-sec">
            <Heading title="Manage Company" />
            <nav>
              <ul>
                <li>
                  <a
                    className="link-added"
                    onClick={() => {
                      this.props.navigate("/dashboard");
                    }}
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a href="#">Manage Company</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="right-sec">
            <div className="btnWrap">
              <div className="search-box">
                <i class="bi bi-search"></i>
                <input
                  type="text"
                  placeholder="Search here..."
                  onChange={this.handleSearch}
                  value={search}
                />
              </div>
              <button
                className="btn btn-primary btn-small"
                onClick={() => {
                  this.props.navigate("/manage-companies/add");
                }}
              >
                <span className="icon-add"></span>Add New
              </button>
              {/* <button
                className={`btn btn-outline-secondary btn-small ${
                  selectedCompaniesforDelete.length > 0 ? "" : "disabled"
                }`}
                onClick={() =>
                  this.handleCompanyDelete(selectedCompaniesforDelete)
                }
              >
                <span className="icon-delete"></span>Delete
              </button> */}
            </div>
          </div>
        </div>

        <div>
          <div className="table-responsive">
            <table className="manage-table table table-striped">
              <thead className="table-dark">
                <tr>
                  {/* <th className="text-center">SELECT</th> */}
                  <th>COMPANY NAME</th>
                  <th>ONBOARDED ON</th>
                  <th>STATUS</th>
                  <th>ACTIONS</th>
                </tr>
              </thead>

              {filteredCompanies.length > 0 ? (
                <tbody>
                  {filteredCompanies.map((company) => {
                    return (
                      <tr key={company.id}>
                        {/* <td>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id={company.id}
                            name={company.id}
                            onChange={() => this.handleChecked(company)}
                          />
                          <label
                            className="form-check-label"
                            for={company.id}
                          ></label>
                        </div>
                      </td> */}
                        <td className="font-gorita-medium">
                          {company.companyName}
                        </td>
                        <td>
                          {moment(company.onboardedOn).format("DD MMM YYYY")}
                        </td>
                        <td>{company.status === 1 ? "Active" : "Inactive"}</td>
                        <td className="actions">
                          <div
                            class="btn-group"
                            role="group"
                            aria-label="Basic outlined example"
                          >
                            <button
                              type="button"
                              class="btn btn-outline-dark"
                              onClick={() => {
                                this.props.navigate("/manage-companies/edit", {
                                  state: company,
                                });
                              }}
                            >
                              <i className="bi bi-eye"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-outline-dark"
                              onClick={() => this.handleCompanyDelete(company)}
                            >
                              <i class="bi bi-trash"></i>
                            </button>
                          </div>

                          {/* <i class="bi bi-eye"></i>
                        <a
                          href="javascript:;"
                          className="link"
                          onClick={() => {
                            this.props.navigate("/manage-companies/edit", {
                              state: company,
                            });
                          }}
                        >
                          View Details
                        </a> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tr>
                  <td colSpan="4" className="text-center noData">
                    No company Found
                  </td>
                </tr>
              )}
            </table>
          </div>
          {/* <div className="pagination">
            <div>
              <span className="page-count">1-8</span>of
              <span className="total-page">25</span>
            </div>
            <div className="pagenation-arrow">
              <span className="icon icon-prev"></span>{" "}
              <span className="icon icon-next"></span>
            </div>
          </div> */}
        </div>
        {isLoading ? (
          <div className="loader-div">
            {" "}
            <LoadingSpinner />{" "}
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}

export default function TempFunction(props) {
  return <ManageCompanies navigate={useNavigate()} />;
}
