import React, { Component } from "react";
import ScrollMenu from "react-horizontal-scroll-menu";
import { ARROW } from "../../../../../assets/index";

const MenuItem = ({ text, selected }) => {
  return <div className={`menu-item ${selected ? "active" : ""}`}>{text}</div>;
};

export const Menu = (list, selected) =>
  list.map((el) => {
    const { tiername } = el;
    return <MenuItem text={tiername} key={tiername} selected={selected} />;
  });

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};

const ArrowLeft = Arrow({ text: <img src={ARROW} />, className: "arrow-prev" });
const ArrowRight = Arrow({
  text: <img src={ARROW} />,
  className: "arrow-next",
});

export default class ScrollTierMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "",
      list: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tiersList !== this.props.tiersList) {
      this.setState({
        selected: this.props.selectedTier
          ? this.props.selectedTier.tiername
          : this.props.tiersList[0].tiername,
        list: this.props.tiersList,
      });
      this.menuItems = Menu(
        this.props.tiersList,
        this.props.defaultSelectedTier
      );
    }
  }

  onSelect = (key) => {
    this.setState({ selected: key });
    this.props.handleParentCall(key);
  };

  render() {
    const { selected } = this.state;
    const menu = this.menuItems;
    return (
      <div>
        <ScrollMenu
          data={menu}
          arrowLeft={ArrowLeft}
          arrowRight={ArrowRight}
          selected={selected}
          onSelect={this.onSelect}
          alignCenter={false}
          hideArrows={true}
          hideSingleArrow={true}
        />
      </div>
    );
  }
}
