import React, { Component } from "react";
import { useNavigate } from "react-router-dom";

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeClasses: [false, false, false],
      userType: localStorage.getItem("type"),
    };
  }

  handleClick = () => {
    this.forceUpdate();
  };

  render() {
    const pathName = window.location.pathname;
    return (
      <section className="sidebarWrapper">
        <ul className="left-col-nav">
          <li
            className={`icon-dashboard ${
              pathName.includes("dashboard") ? "active" : ""
            }`}
          >
            <a onClick={() => this.props.navigate("/dashboard")}>
              <span>Dashboard</span>
            </a>
          </li>

          {this.state.userType === "SUPERADMIN" ||
          this.state.userType === "ADMIN" ? (
            <li
              className={`icon-company ${
                pathName.includes("manage-companies") ? "active" : ""
              }`}
            >
              <a onClick={() => this.props.navigate("/manage-companies")}>
                <span>Manage Companies</span>
              </a>
            </li>
          ) : (
            <li className="icon-company d-none">
              <a>
                <span>Manage Companies</span>
              </a>
            </li>
          )}

          {this.state.userType === "SUPERADMIN" ||
          this.state.userType === "ADMIN" ? (
            <li
              className={`icon-tier ${
                pathName.includes("manage-tiers") ? "active" : ""
              }`}
            >
              <a onClick={() => this.props.navigate("/manage-tiers")}>
                <span>Manage Tiers</span>
              </a>
            </li>
          ) : (
            <li className="icon-tier d-none">
              <a>
                <span>Manage Tiers</span>
              </a>
            </li>
          )}

          {this.state.userType === "SUPERADMIN" ||
          this.state.userType === "COMPANYREPRESENTATIVE" ||
          this.state.userType === "ADMIN" ? (
            <li
              className={`icon-user-mgmt ${
                pathName.includes("manage-users") ? "active" : ""
              }`}
            >
              <a onClick={() => this.props.navigate("/manage-users")}>
                <span>User Management</span>
              </a>
            </li>
          ) : (
            <li className="icon-user-mgmt d-none">
              <a>
                <span>User Management</span>
              </a>
            </li>
          )}

          {this.state.userType === "SUPERADMIN" ||
          this.state.userType === "ADMIN" ? (
            <li
              className={`icon-deal-mgmt ${
                pathName.includes("manage-deals") ? "active" : ""
              }`}
            >
              <a onClick={() => this.props.navigate("/manage-deals")}>
                <span>Deal Management</span>
              </a>
            </li>
          ) : (
            <li className="icon-deal-mgmt d-none">
              <a>
                <span>Deal Management</span>
              </a>
            </li>
          )}

          {this.state.userType === "SUPERADMIN" ||
          this.state.userType === "REPORTSUSER" ||
          this.state.userType === "ADMIN" ? (
            <li
              className={`icon-report ${
                pathName.includes("reports") ? "active" : ""
              }`}
            >
              <a onClick={() => this.props.navigate("/reports")}>
                <span>Reports</span>
              </a>
            </li>
          ) : (
            <li className="icon-report d-none">
              <a>
                <span>Reports</span>
              </a>
            </li>
          )}
        </ul>
        <section className="powered-txt">
          Powered by <span className="influx-logo"></span>
        </section>
      </section>
    );
  }
}

export default function TempFunction(props) {
  return <SideBar navigate={useNavigate()} />;
}
