import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import MainComponent from "./components/MainComponent";
import DashBoard from "./components/MainComponent/DashBoard";
import ManageCompanies from "./components/MainComponent/ManageCompanies";
import AddCompany from "./components/MainComponent/ManageCompanies/AddCompany";
import EditCompany from "./components/MainComponent/ManageCompanies/EditCompany";
import ManageTiers from "./components/MainComponent/ManageTiers";
import AddTier from "./components/MainComponent/ManageTiers/AddTier";
import EditTier from "./components/MainComponent/ManageTiers/EditTier";
import ViewTiers from "./components/MainComponent/ManageTiers/ViewTiers";
import UserManagement from "./components/MainComponent/UserManagement";
import AddSingleUser from "./components/MainComponent/UserManagement/AddSingleUser";
import AddUserInBulk from "./components/MainComponent/UserManagement/AddUserInBulk";
import ViewUsers from "./components/MainComponent/UserManagement/ViewUsers";
import SignIn from "./components/SignIn";
import DealManagement from "./components/MainComponent/DealManagement";
import ViewDeals from "./components/MainComponent/DealManagement/ViewDeals";
import EditUser from "./components/MainComponent/UserManagement/EditUser";
import Reports from "./components/MainComponent/Reports";

function App() {
  const isAuth = localStorage.getItem("token");

  return (
    <>
      <Router>
        <Routes>
          {isAuth === null ? (
            <Route path="/" element={<SignIn />}></Route>
          ) : (
            <Route path="/" element={<MainComponent />}>
              <Route path="manage-tiers" element={<ManageTiers />}></Route>
              <Route path="manage-tiers/tiers" element={<ViewTiers />}></Route>
              <Route path="manage-tiers/tiers/add" element={<AddTier />}></Route>
              <Route path="manage-tiers/tiers/edit" element={<EditTier />}></Route>
              <Route path="manage-users" element={<UserManagement />}></Route>

              <Route path="reports" element={<Reports />}></Route>
              <Route path="dashboard" element={<DashBoard />}></Route>
              <Route path="manage-companies" element={<ManageCompanies />}></Route>

              <Route path="manage-companies/add" element={<AddCompany />}></Route>
              <Route path="manage-users/users" element={<ViewUsers />}></Route>
              <Route path="manage-users/users/add-single-user" element={<AddSingleUser />}></Route>
              <Route path="manage-users/users/add-user-in-bulk" element={<AddUserInBulk />}></Route>
              <Route path="manage-users/user/edit" element={<EditUser />}></Route>
              <Route path="manage-deals" element={<DealManagement />}></Route>
              <Route path="manage-deals/deals" element={<ViewDeals />}></Route>
              <Route path="manage-companies/edit" element={<EditCompany />}></Route>
            </Route>
          )}
        </Routes>
      </Router>
    </>
  );
}

export default App;
