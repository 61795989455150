import React, { Component } from "react";
import AmcLogo from "../../assets/images/amc-logo-red.png";
import * as API from "../../configuration/apiconfig";
import LoadingSpinner from "../LoadingSpinner";
import $ from "jquery";
import { EYEOPEN, EYECLOSE } from "../../assets/index";

export default class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      message: "",
      isLoading: false,
      emailError: "",
      adminName: "",
      showPassword: false,
    };
  }

  componentDidMount = () => {
    $(window).on("load resize", function () {
      var devHeight = $(window).height();
      if ($(".deals-login").length > 0) {
        $(".deals-login").css("min-height", devHeight);
      }
    });
  };

  handleValuesChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  isValidEmail = (e) => {
    if (e.target.value) {
      let regEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (!regEmail.test(e.target.value)) {
        this.setState({
          emailError: "Please enter valid email address",
        });
      } else {
        this.setState({
          emailError: "",
        });
      }
    }
  };

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  signInHandler = async (e) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    const data = await API.getAccessToken(
      this.state.username,
      this.state.password
    )
      .then((response) => {
        const name = response.data.firstname;
        const userType = response.data.type;
        localStorage.setItem("token", this.state.username);
        localStorage.setItem("adminname", name);
        localStorage.setItem("type", userType);
        window.location.href = "/dashboard";
      })
      .catch((a) =>
        this.setState({
          message: "The credentials used do not match. Please try again.",
        })
      );
    this.setState({ isLoading: false });
  };

  render() {
    const { username, password, message, isLoading, emailError } = this.state;
    const isEnabled = username.length > 0 && password.length > 0;

    return (
      <div className="deals-login">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="deals-logo">
                <figure>
                  <a href="#" title="AMC Cinemas">
                    <img
                      src={AmcLogo}
                      alt="AMC Cinemas"
                      className="amc-login-logo"
                    />
                  </a>
                </figure>
              </div>
              <div className="form-input-grp">
                <div className={`input-form ${emailError ? "mb-2" : ""}`}>
                  <label
                    htmlFor="usernameField"
                    className="form-label visually-hidden"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="usernameField"
                    name="username"
                    placeholder="Email Address"
                    onBlur={this.isValidEmail}
                    onChange={this.handleValuesChange}
                    value={username}
                  />
                </div>
                <div className="errorMessage">{emailError}</div>

                <div className="input-form">
                  <label
                    htmlFor="passwordField"
                    className="form-label visually-hidden"
                  >
                    Password
                  </label>
                  <div className="enter-password">
                    <input
                      type={this.state.showPassword ? "text" : "password"}
                      className="form-control"
                      id="passwordField"
                      name="password"
                      placeholder="Enter Password"
                      onChange={this.handleValuesChange}
                      value={password}
                    />
                    <span
                      className="eye-icon"
                      id="addon-wrapping"
                      onClick={this.handleClickShowPassword}
                    >
                      <img
                        src={this.state.showPassword ? EYECLOSE : EYEOPEN}
                        alt="eye-icon"
                      />
                    </span>
                  </div>
                </div>
                {/* <div className="verify-notification clearfix">
                  <a href="#" className="forget-psw-link">
                    Forgot Password?
                  </a>
                </div> */}

                <div className="btnWrap signin-btn">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.signInHandler}
                    disabled={!isEnabled}
                  >
                    Submit
                  </button>
                </div>
                {message ? <div className="message">{message}</div> : ""}
              </div>
              <div className="copy-txt">
                <p className="text-center">
                  © Copyright {new Date().getFullYear()} Saudi Cinema Company
                  LTD. All Rights Reserved.
                </p>
              </div>
              {isLoading ? (
                <div className="loader-div">
                  {" "}
                  <LoadingSpinner />{" "}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
