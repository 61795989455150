import React, { Component } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Heading from "../../Heading";
import * as API from "../../../../configuration/apiconfig";
import LoadingSpinner from "../../../LoadingSpinner";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

class ViewTiers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tiersList: [],
      isLoading: false,
      companyId: this.props.navigateState.id,
      selectedTierForDelete: [],
      isDisabled: true,
      isChecked: false,
    };
  }

  getTiers = async () => {
    this.setState({
      isLoading: true,
    });
    const responseData = await API.callEndpoint(
      "GET",
      "Bearer",
      `/api/companies/${this.state.companyId}/tiers`
    ).then((response) => {
      return response.data;
    });
    this.setState({
      tiersList: responseData,
      isLoading: false,
    });
  };

  componentDidMount = async () => {
    const apiResponseData = await this.getTiers();
  };

  handleChecked = (row) => {
    this.setState({
      isDisabled: false,
      selectedTierForDelete: [...this.state.selectedTierForDelete, row],
    });
    if (this.state.selectedTierForDelete.includes(row)) {
      const index = this.state.selectedTierForDelete.findIndex((obj) => {
        return obj.id === row.id;
      });
      this.state.selectedTierForDelete.splice(index, 1);
      this.setState({
        selectedTierForDelete: [...this.state.selectedTierForDelete],
      });
    }
  };

  handleTierDelete = (obj) => {
    // const selectedObj = array.map((obj) => {
    //   return obj.id;
    // });
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to delete the Tier",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: "#e51937",
      cancelButtonColor: "#918d8d",
    }).then((result) => {
      if (result.isConfirmed) {
        API.callEndpoint(
          "DELETE",
          "Bearer",
          `/api/companies/${this.state.companyId}/tiers/${obj.id}`
        ).then((response) => {
          Swal.fire({
            icon: "success",
            title: "Tier deleted successfully.",
            showConfirmButton: true,
            confirmButtonColor: "#e51937",
          }).then((result) => {
            this.getTiers();
            // this.setState({
            //   selectedTierForDelete: [],
            // });
          });
        });
      }
    });
  };

  render() {
    const {
      tiersList,
      isLoading,
      isDisabled,
      selectedTierForDelete,
      isChecked,
    } = this.state;
    return (
      <section className="companiesWrapper">
        <div className="top-bar">
          <div className="left-sec">
            <Heading title="View Tiers" />
            <nav>
              <ul>
                <li>
                  <a
                    className="link-added"
                    onClick={() => {
                      this.props.navigate("/dashboard");
                    }}
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    className="link link-added"
                    onClick={() => {
                      this.props.navigate("/manage-tiers");
                    }}
                  >
                    Manage Tiers
                  </a>
                </li>
                <li>
                  <a className="link-added">
                    {this.props.navigateState.companyName}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="right-sec">
            <div className="btnWrap">
              <button
                className="btn btn-primary btn-small"
                onClick={() => {
                  this.props.navigate("/manage-tiers/tiers/add", {
                    state: this.props.navigateState,
                  });
                }}
              >
                <span className="icon-add"></span>Add New
              </button>
              {/* <button
                className={`btn btn-outline-secondary btn-small ${
                  selectedTierForDelete.length > 0 ? "" : "disabled"
                }`}
                onClick={() => this.handleTierDelete(selectedTierForDelete)}
              >
                <span className="icon-delete"></span>Delete
              </button> */}
            </div>
          </div>
        </div>

        <div>
          <div className="table-responsive">
            {tiersList.length > 0 ? (
              <table className="manage-table table table-striped">
                <thead className="table-dark">
                  <tr>
                    {/* <th className="text-center">SELECT</th> */}
                    <th>TIER NAME</th>
                    <th>ACTIONS</th>
                  </tr>
                </thead>

                <tbody>
                  {tiersList.map((tier) => {
                    return (
                      <tr key={tier.id}>
                        {/* <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={tier.id}
                              name={tier.id}
                              onChange={() => this.handleChecked(tier)}
                            />
                            <label
                              className="form-check-label"
                              for={tier.id}
                            ></label>
                          </div>
                        </td> */}
                        <td className="font-gorita-medium">{tier.tiername}</td>
                        <td className="actions">
                          <div
                            class="btn-group"
                            role="group"
                            aria-label="Basic outlined example"
                          >
                            <button
                              type="button"
                              class="btn btn-outline-dark"
                              onClick={() => {
                                this.props.navigate(
                                  "/manage-tiers/tiers/edit",
                                  {
                                    state: {
                                      company: this.props.navigateState,
                                      tier,
                                    },
                                  }
                                );
                              }}
                            >
                              <i className="bi bi-eye"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-outline-dark"
                              onClick={() => this.handleTierDelete(tier)}
                            >
                              <i class="bi bi-trash"></i>
                            </button>
                          </div>
                          {/* {" "}
                          <a
                            className="link redirect-link"
                            onClick={() => {
                              this.props.navigate("/manage-tiers/tiers/edit", {
                                state: {
                                  company: this.props.navigateState,
                                  tier,
                                },
                              });
                            }}
                          >
                            View Details
                          </a> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="text-center noData">
                There are no tiers added for this company
              </div>
            )}
          </div>
        </div>

        {isLoading ? (
          <div className="loader-div">
            {" "}
            <LoadingSpinner />{" "}
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}

export default function TempFunction(props) {
  const { state } = useLocation();
  return <ViewTiers navigate={useNavigate()} navigateState={state} />;
}
