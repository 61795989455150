import React, { Component } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "sweetalert2/src/sweetalert2.scss";
import * as API from "../../../../configuration/apiconfig";
import Heading from "../../Heading";
import ScrollTierMenu from "./ScrollTierMenu";
import TicketingDeals from "./TicketingDeals";
import FoodAndBeveragesDeals from "./FoodAndBeveragesDeals";
import CappingDeals from "./CappingDeals";
import LoadingSpinner from "../../../LoadingSpinner";

class ViewDeals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCompany: this.props.navigateState,
      tiersList: [],
      selectedTier: undefined,
      tierName: "",
      tierId: 0,
      dealsList: [],
      isLoading: false,
      cappingDetails: [],
      cappingForSelectedTier: {},
      errors: {
        tksAllowedPerTransaction: "",
        bksAllowedPerDay: "",
        tktsAllowedPerMonth: "",
      },
      cinemasList: [],
      moviesList: [],
      cinemaRulesList: [],
      movieRulesList: [],
      cinemaSearch: "",
      methodTypeForCinemaAndMovieRules: "POST",
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.setState({
      isLoading: true,
    });

    // get tiers for specific company api call
    const responseData = await API.callEndpoint("GET", "Bearer", `/api/companies/${this.state.selectedCompany.id}/tiers`).then((response) => {
      return response.data;
    });

    const firstTier = responseData[0];
    this.setState(
      {
        tiersList: responseData,
        tierName: responseData[0].tiername,
        tierId: responseData[0].id,
        cappingForSelectedTier: responseData[0],
      },
      () => {
        this.handleMethodTypeForCinemaAndMovieRules(responseData[0]);
      }
    );

    // get deals for specific tier api call
    const getDealsApiResponse = await API.callEndpoint("GET", "Bearer", `/api/companies/${this.state.selectedCompany.id}/tiers/${firstTier.id}/deals`).then((response) => {
      return response.data;
    });

    // get cinemas list api call
    const getCinemas = await API.callEndpoint("GET", "Bearer", "/api/cinemas").then((response) => {
      return response.data;
    });

    // get movies list api call
    const getMovies = await API.callEndpoint("GET", "Bearer", "/api/films").then((response) => {
      return response.data;
    });

    const apiGetCinemaRules = await API.callEndpoint("GET", "Bearer", `/api/companies/${this.state.selectedCompany.id}/tiers/${firstTier.id}/cinemarules`).then((response) => {
      return response.data;
    });

    const apiGetMovieRules = await API.callEndpoint("GET", "Bearer", `api/companies/${this.state.selectedCompany.id}/tiers/${firstTier.id}/movierules`).then((response) => {
      return response.data;
    });

    this.setState({
      dealsList: getDealsApiResponse,
      isLoading: false,
      cinemasList: getCinemas,
      moviesList: getMovies,
      cinemaRulesList: apiGetCinemaRules,
      movieRulesList: apiGetMovieRules,
    });
  };

  handleCapping = async () => {
    const responseData = await API.callEndpoint("GET", "Bearer", `/api/companies/${this.state.selectedCompany.id}/tiers`).then((response) => {
      return response.data;
    });
    this.setState({
      tiersList: responseData,
    });
  };

  handleSelectedTier = async (name) => {
    console.log("handleSelectedTier");
    this.setState({
      isLoading: true,
    });

    let tierData = this.state.tiersList.find((tier) => tier.tiername === name);

    // get deals api call on click on tiername
    const dealData = await API.callEndpoint("GET", "Bearer", `/api/companies/${this.state.selectedCompany.id}/tiers/${tierData.id}/deals`).then((response) => {
      return response.data;
    });

    // get cinema rules based on tier
    const apiGetCinemaRules = await API.callEndpoint("GET", "Bearer", `/api/companies/${this.state.selectedCompany.id}/tiers/${tierData.id}/cinemarules`).then((response) => {
      return response.data;
    });

    // get movie rules based on tier
    const apiGetMovieRules = await API.callEndpoint("GET", "Bearer", `api/companies/${this.state.selectedCompany.id}/tiers/${tierData.id}/movierules`).then((response) => {
      return response.data;
    });

    await this.setState(
      {
        dealsList: dealData,
        tierName: tierData.tiername,
        tierId: tierData.id,
        isLoading: false,
        cappingForSelectedTier: tierData,
        cinemaRulesList: apiGetCinemaRules,
        cinemaSearch: "",
        movieRulesList: apiGetMovieRules,
      },
      () => {
        this.handleMethodTypeForCinemaAndMovieRules(tierData);
      }
    );
  };

  handleMethodTypeForCinemaAndMovieRules = (tierData) => {
    if (tierData.bksAllowedPerDay == null && tierData.tktsAllowedPerMonth == null && tierData.tksAllowedPerTransaction == null) {
      this.setState({
        methodTypeForCinemaAndMovieRules: "POST",
      });
    } else {
      this.setState({
        methodTypeForCinemaAndMovieRules: "PATCH",
      });
    }
  };

  updateTierDataOnSuccess = async () => {
    const tierData = await API.callEndpoint("GET", "Bearer", `/api/companies/${this.state.selectedCompany.id}/tiers`).then((response) => {
      return response.data;
    });

    const apiGetCinemaRules = await API.callEndpoint("GET", "Bearer", `/api/companies/${this.state.selectedCompany.id}/tiers/${this.state.tierId}/cinemarules`).then((response) => {
      return response.data;
    });

    const apiGetMovieRules = await API.callEndpoint("GET", "Bearer", `api/companies/${this.state.selectedCompany.id}/tiers/${this.state.tierId}/movierules`).then((response) => {
      return response.data;
    });

    this.setState({
      tiersList: tierData,
      cinemaRulesList: apiGetCinemaRules,
      movieRulesList: apiGetMovieRules,
    });
  };
  handleTicketingDeals = async () => {
    this.setState({
      isLoading: false,
    });
    const getDealsApiResponse = await API.callEndpoint("GET", "Bearer", `/api/companies/${this.state.selectedCompany.id}/tiers/${this.state.tierId}/deals`).then((response) => {
      return response.data;
    });

    this.setState({
      dealsList: getDealsApiResponse,
      isLoading: false,
    });
  };

  handleDealUpdate = async () => {
    console.log("--------handleDealUpdate--------");
    const updatedDealData = await API.callEndpoint("GET", "Bearer", `/api/companies/${this.state.selectedCompany.id}/tiers`).then((response) => {
      return response.data;
    });

    await this.setState({
      tiersList: updatedDealData,
    });

    const updatedCaapingForSelectedTierOnToggleSwitch = updatedDealData.find((deal) => deal.tiername == this.state.cappingForSelectedTier.tiername);

    await this.setState(
      {
        cappingForSelectedTier: updatedCaapingForSelectedTierOnToggleSwitch,
      },
      () => {
        this.handleMethodTypeForCinemaAndMovieRules(updatedCaapingForSelectedTierOnToggleSwitch);
      }
    );
  };

  handleTicketInfoValueChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      cappingForSelectedTier: {
        ...this.state.cappingForSelectedTier,
        [name]: value,
      },
    });
    this.setState({
      tiersList: [...this.state.cappingForSelectedTier],
    });
  };

  handleCinemaValue = (searchVal) => {
    this.setState({
      cinemaSearch: searchVal,
    });
  };

  render() {
    const { selectedCompany, tiersList, tierName, tierId, dealsList, isLoading, cinemasList, moviesList, cinemaSearch, bookingAllowedHours } = this.state;

    const ticketingDeals = dealsList.filter((deal) => {
      return deal.dealType === "ticket";
    });

    const fnbDeals = dealsList.filter((deal) => {
      return deal.dealType === "fnb";
    });

    return (
      <section className="companiesWrapper">
        <div className="top-bar">
          <div className="left-sec">
            <Heading title="View Deals" />
            <nav>
              <ul>
                <li>
                  <a
                    className="link-added"
                    onClick={() => {
                      this.props.navigate("/dashboard");
                    }}
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    className="link link-added"
                    onClick={() => {
                      this.props.navigate("/manage-deals");
                    }}
                  >
                    Deal Management
                  </a>
                </li>
                <li>
                  <a className="ink-added">{this.props.navigateState.companyName}</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="scrollable-tier-section">
          <ScrollTierMenu tiersList={tiersList} selectedTier={this.state.cappingForSelectedTier} handleParentCall={this.handleSelectedTier} />
        </div>
        <div className="list-of-deals-section">
          <TicketingDeals
            tierName={tierName}
            tierId={tierId}
            selectedCompany={selectedCompany}
            ticketingDeals={ticketingDeals}
            handleUpdatedDeals={this.handleTicketingDeals}
            enableDeals={this.state.cappingForSelectedTier}
            handleEnableTicketingDeals={this.handleDealUpdate}
          />
          <FoodAndBeveragesDeals
            tierName={tierName}
            tierId={tierId}
            selectedCompany={selectedCompany}
            fnbDeals={fnbDeals}
            handleUpdatedDeals={this.handleTicketingDeals}
            enableFNBDeals={this.state.cappingForSelectedTier}
            handleEnableTicketingDeals={this.handleDealUpdate}
          />
          <CappingDeals
            tierName={tierName}
            selectedCompany={selectedCompany}
            tierId={tierId}
            ticketCounts={this.state.cappingForSelectedTier}
            cinemaRulesList={this.state.cinemaRulesList}
            movieRulesList={this.state.movieRulesList}
            handleUpdateTier={this.handleCapping}
            handleTicketInfoValueChange={this.handleTicketInfoValueChange}
            errors={this.state.errors}
            cinemaSearchValue={this.handleCinemaValue}
            cinemaSearch={cinemaSearch}
            methodTypeForCinemaAndMovieRules={this.state.methodTypeForCinemaAndMovieRules}
            handleMethodTypeForCinemaAndMovieRules={this.handleMethodTypeForCinemaAndMovieRules}
            updateTierDataOnSuccess={this.updateTierDataOnSuccess}
          />
        </div>

        {isLoading ? (
          <div className="loader-div">
            {" "}
            <LoadingSpinner />{" "}
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}

export default function TempFunction(props) {
  const { state } = useLocation();
  return <ViewDeals navigate={useNavigate()} navigateState={state} />;
}
