import $ from "jquery";
import React, { Component } from "react";
import DashBoard from "./DashBoard";
import Header from "./Header";
import ManageCompanies from "./ManageCompanies";
import SideBar from "./SideBar";
import { Outlet } from "react-router-dom";

export default class MainComponent extends Component {
  // componentDidMount = () => {
  //   $(window).on("load resize", function () {
  //     var devHeight = $(window).height();
  //     var headerHeight = $("header").outerHeight(true);

  //     if ($(".left-col").length > 0) {
  //       var colLeftMargin = parseInt($(".left-col").css("margin-top"));
  //       var calLeftColHeight = parseInt(
  //         devHeight - headerHeight - colLeftMargin
  //       );
  //       $(".left-col").css("min-height", calLeftColHeight);
  //     }
  //   });
  // };

  render() {
    return (
      <div className="homeComponent">
        <div className="header">
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="container-fluid">
            <section className="row flex-nowrap">
              <section className="col-xl-2 col-lg-3 col-md-3 left-col">
                <SideBar />
              </section>
              <section className="col-xl-10 col-lg-9 col-md-9 right-col">
                <Outlet />
              </section>
            </section>
          </section>
        </div>
      </div>
    );
  }
}
