import React, { Component } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Heading from "../../Heading";
import * as API from "../../../../configuration/apiconfig";
import LoadingSpinner from "../../../LoadingSpinner";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { CHECKED_GREEN } from "../../../../assets/index";

// let intervalID;
class ViewUsers extends Component {
  constructor(props) {
    super(props);
    this.searchInput = React.createRef();
    this.state = {
      userList: [],
      tiersList: [],
      isLoading: false,
      companyId: this.props.navigateState.id,
      isDisabled: true,
      selectedUserForDelete: [],
      bulkUserProgressBarVisibilityInCookie: false,
      isBulkUserButtonDisabled: false,
      isSuccess: false,
      duplicateUsersList: [],
      currentPage: 1,
      selectedCompany: {},
      bulkUploadInterval: "",
      isSelectedAll: false,
      search: "",
      filteredUsers: [],
    };
  }

  getUser = async (count) => {
    this.setState({
      ...this.state,
      isLoading: true,
    });

    const responseData = await API.callEndpoint(
      "GET",
      "Bearer",
      `/api/users?page=${count}&companyid=${this.state.companyId}`
    ).then((response) => {
      return response.data;
    });
    const tierData = await API.callEndpoint(
      "GET",
      "Bearer",
      `/api/companies/${this.props.navigateState.id}/tiers`
    ).then((response) => {
      return response.data;
    });
    this.setState(
      {
        tiersList: tierData,
        userList: responseData,
        filteredUsers: responseData,
        currentPage:
          responseData[0].data.length > 0
            ? Number(responseData[0].currentPage)
            : this.state.currentPage,
        isLoading: false,
      },
      () => {
        console.log(this.state.currentPage);
      }
    );
  };

  getSpecificCompanyDetail = async () => {
    const getComapaniesApiResponse = await API.callEndpoint(
      "GET",
      "Bearer",
      `/api/companies?id=${this.props.navigateState.id}`
    ).then((response) => {
      return response.data[0];
    });
    this.setState({
      selectedCompany: getComapaniesApiResponse,
    });
  };

  componentDidMount = async () => {
    await this.getUser(this.state.currentPage);
    this.getSpecificCompanyDetail();

    if (
      API.getValueFromCookie(
        `BulkUserProgressBarVisibility_${this.state.companyId}`
      ) == true ||
      API.getValueFromCookie(
        `BulkUserProgressBarVisibility_${this.state.companyId}`
      ) == "true"
    ) {
      this.setState({
        isBulkUserButtonDisabled: true,
        bulkUserProgressBarVisibilityInCookie: true,
      });

      const intervalID = setInterval(async () => {
        this.setState(
          {
            bulkUploadInterval: intervalID,
          },
          async () => {
            // console.log("this.state.bulkUploadInterval: " + this.state.bulkUploadInterval);
            await API.uploadFile(
              "GET",
              this.props.navigateState.uploadedFile,
              this.state.companyId
            ).then(async (response) => {
              if (response[0].status === 2) {
                if (response[0].log.duplicateUsers.length === 0) {
                  clearInterval(intervalID);
                  await this.getUser(this.state.currentPage);
                  this.setState({
                    isSuccess: true,
                    isBulkUserButtonDisabled: false,
                    bulkUserProgressBarVisibilityInCookie: false,
                  });

                  document.cookie = `BulkUserProgressBarVisibility_${this.state.companyId}=false; path=/`;

                  setTimeout(() => {
                    this.setState({
                      isSuccess: false,
                    });
                  }, 15000);
                } else {
                  await this.getUser(this.state.currentPage);
                  this.setState({
                    isBulkUserButtonDisabled: true,
                    duplicateUsersList: response[0].log.duplicateUsers,
                    bulkUserProgressBarVisibilityInCookie: false,
                  });

                  document.cookie = `BulkUserProgressBarVisibility_${this.state.companyId}=false; path=/`;
                }
              }
            });
          }
        );
      }, 30000);
    }
  };

  componentWillUnmount = () => {
    // clearInterval(intervalID);
    if (this.state.bulkUploadInterval != "") {
      let bulkUploadInterval = this.state.bulkUploadInterval;
      this.setState(
        {
          bulkUploadInterval: "",
        },
        () => {
          // console.log("this.state.bulkUploadInterval: " + this.state.bulkUploadInterval);
          clearInterval(bulkUploadInterval);
        }
      );
    }
  };

  handleChecked = (row) => {
    this.setState({
      isDisabled: false,
      isSelectedAll: false,
      selectedUserForDelete: [...this.state.selectedUserForDelete, row],
    });

    const indexInFilteredUsers = this.state.filteredUsers[0].data.findIndex(
      (obj) => {
        return obj.id === row.id;
      }
    );

    if (this.state.selectedUserForDelete.includes(row)) {
      const index = this.state.selectedUserForDelete.findIndex((obj) => {
        return obj.id === row.id;
      });

      this.state.selectedUserForDelete.splice(index, 1);
      this.setState({
        selectedUserForDelete: [...this.state.selectedUserForDelete],
      });
    }

    if (
      this.state.filteredUsers[0].data[indexInFilteredUsers].isChecked == true
    ) {
      this.state.filteredUsers.map((cinema) => {
        cinema.data[indexInFilteredUsers].isChecked = false;
      });
    } else {
      this.state.filteredUsers.map((cinema) => {
        cinema.data[indexInFilteredUsers].isChecked = true;
      });
    }
  };

  // handleChecked = (row) => {
  //   this.setState({
  //     isDisabled: false,
  //     isSelectedAll: false,
  //     selectedUserForDelete: [...this.state.selectedUserForDelete, row],
  //   });

  //   const indexInUserList = this.state.userList[0].data.findIndex((obj) => {
  //     return obj.id === row.id;
  //   });

  //   console.log(indexInUserList);
  //   if (this.state.selectedUserForDelete.includes(row)) {
  //     const index = this.state.selectedUserForDelete.findIndex((obj) => {
  //       return obj.id === row.id;
  //     });

  //     this.state.selectedUserForDelete.splice(index, 1);
  //     this.setState({
  //       selectedUserForDelete: [...this.state.selectedUserForDelete],
  //     });
  //   }

  //   if (this.state.userList[0].data[indexInUserList].isChecked == true) {
  //     this.state.userList.map((cinema) => {
  //       cinema.data[indexInUserList].isChecked = false;
  //     });
  //   } else {
  //     this.state.userList.map((cinema) => {
  //       cinema.data[indexInUserList].isChecked = true;
  //     });
  //   }

  //   this.setState({
  //     userList: [...this.state.userList],
  //   });
  // };

  handleSelectAll = () => {
    this.setState({
      isSelectedAll: true,
    });

    const addedChecked = this.state.userList.map((modifier) => {
      return {
        ...modifier,
        data: modifier.data.map((item) => {
          return {
            ...item,
            isChecked: true,
          };
        }),
      };
    });

    const addCheckedKey = this.state.filteredUsers.map((user) => {
      return {
        ...user,
        data: user.data.map((item) => {
          return {
            ...item,
            isChecked: true,
          };
        }),
      };
    });

    this.setState({
      filteredUsers: addCheckedKey,
      userList: addedChecked,
      selectedUserForDelete: addCheckedKey.flatMap((user) =>
        user.data.filter((data) => data.isChecked == true)
      ),
    });
  };

  handleUnSelectAll = () => {
    this.setState({
      isSelectedAll: false,
      userList: this.state.userList.map((modifier) => {
        return {
          ...modifier,
          data: modifier.data.map((item) => {
            return {
              ...item,
              isChecked: false,
            };
          }),
        };
      }),

      filteredUsers: this.state.filteredUsers.map((user) => {
        return {
          ...user,
          data: user.data.map((item) => {
            return {
              ...item,
              isChecked: false,
            };
          }),
        };
      }),
      selectedUserForDelete: [],
    });
  };

  handleUserDelete = (array) => {
    if (this.state.search != "") {
      this.setState({
        search: "",
      });
    }
    const selectedObj = array.map((obj) => {
      return obj.id;
    });
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to delete the user",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: "#e51937",
      cancelButtonColor: "#918d8d",
    }).then((result) => {
      if (result.isConfirmed) {
        if (array.length === 1) {
          API.callEndpoint(
            "DELETE",
            "Bearer",
            `/api/users/${selectedObj[0]}`
          ).then(() => {
            Swal.fire({
              icon: "success",
              title: "User deleted successfully.",
              showConfirmButton: true,
              confirmButtonColor: "#e51937",
            }).then(() => {
              this.getUser(this.state.currentPage);
              this.setState({
                selectedUserForDelete: [],
              });
            });
          });
        } else {
          API.callEndpoint("POST", "Bearer", "/api/users/deleteMultipleUsers", {
            userids: selectedObj,
          }).then(() => {
            Swal.fire({
              icon: "success",
              title: "Users deleted successfully.",
              showConfirmButton: true,
              confirmButtonColor: "#e51937",
            }).then(() => {
              this.getUser(this.state.currentPage);
              this.setState({
                selectedUserForDelete: [],
                isSelectedAll: false,
              });
            });
          });
        }
      }
    });
  };

  handleSearch = () => {
    this.setState({
      isLoading: true,
    });
    API.callEndpoint(
      "GET",
      "Bearer",
      `/api/users?page=${this.state.currentPage}&companyid=${this.state.companyId}&name=${this.state.search}`
    )
      .then((response) => {
        this.setState({
          filteredUsers: response.data,
        });
        if (response) {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        return error;
      });
  };

  goToPreviousUserPage = async () => {
    let pageNo = Number(this.state.currentPage) - 1;
    await this.getUser(pageNo);

    this.setState({
      isSelectedAll: false,
      selectedUserForDelete: [],
    });
  };

  goToNextUserPage = async () => {
    let pageCount = Number(this.state.currentPage) + 1;
    await this.getUser(pageCount);
    this.setState({
      isSelectedAll: false,
      selectedUserForDelete: [],
    });
  };

  render() {
    const {
      userList,
      isLoading,
      tiersList,
      selectedUserForDelete,
      isBulkUserButtonDisabled,
      isSuccess,
      duplicateUsersList,
      bulkUserProgressBarVisibilityInCookie,
      isSelectedAll,
      search,
      filteredUsers,
    } = this.state;

    return (
      <section className="companiesWrapper view-user-page">
        <div className="top-bar">
          <div className="left-sec">
            <Heading title="View Users" />
            <nav>
              <ul>
                <li>
                  <a
                    className="link-added"
                    onClick={() => {
                      this.props.navigate("/dashboard");
                    }}
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    className="link link-added"
                    onClick={() => {
                      this.props.navigate("/manage-users");
                    }}
                  >
                    User Management
                  </a>
                </li>
                <li>
                  <a className="ink-added">
                    {this.props.navigateState.companyName}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="right-sec">
            <div className="btnWrap">
              <div className="search-box">
                <i class="bi bi-search"></i>
                <input
                  type="text"
                  placeholder="Search here..."
                  onChange={(e) => {
                    this.setState({
                      search: e.target.value,
                    });
                  }}
                  value={search}
                />
                <button
                  className={`btn search-btn ${
                    search.trim().length < 3 ? "disabled" : ""
                  }`}
                  onClick={this.handleSearch}
                >
                  Search
                </button>
              </div>
              <button
                className="btn btn-primary btn-small"
                onClick={() => {
                  this.props.navigate("/manage-users/users/add-single-user", {
                    state: {
                      ...this.props.navigateState,
                      selectedCompany: this.state.selectedCompany,
                    },
                  });
                }}
              >
                <span className="icon-add"></span>Add User
              </button>
              <button
                className={`btn btn-primary btn-small ${
                  isBulkUserButtonDisabled === true ? "disabled" : ""
                }`}
                onClick={() => {
                  this.props.navigate("/manage-users/users/add-user-in-bulk", {
                    state: {
                      ...this.props.navigateState,
                      tiers: this.state.tiersList,
                    },
                  });
                }}
              >
                <span className="icon-add"></span>Add User in Bulk
              </button>
              <button
                className={`btn btn-primary btn-small status-btn ${
                  isSelectedAll ? "disabled" : ""
                }`}
                onClick={this.handleSelectAll}
              >
                Select All
              </button>
              <button
                className={`btn btn-primary btn-small  status-btn  ${
                  selectedUserForDelete.length > 1 ? "" : "disabled"
                }`}
                onClick={this.handleUnSelectAll}
              >
                Unselect All
              </button>
              <button
                className={`btn btn-outline-secondary btn-small ${
                  selectedUserForDelete.length > 0 ? "" : "disabled"
                }`}
                onClick={() => this.handleUserDelete(selectedUserForDelete)}
              >
                <span className="icon-delete"></span>Delete
              </button>
            </div>
          </div>
        </div>

        {bulkUserProgressBarVisibilityInCookie == true ? (
          <div className="user-progress-bar page">
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated w-100 bg-warning"
                role="progressbar"
                aria-label="Animated striped example"
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                Bulk user upload in progress
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {duplicateUsersList.length > 0 ? (
          <div
            className="duplicate-alert alert alert-danger fade show mb-0 text-center"
            role="alert"
            onClick={() => {
              this.props.navigate("/manage-users/users/add-user-in-bulk", {
                state: {
                  ...this.props.navigateState,
                  duplicateUsersList: this.state.duplicateUsersList,
                },
              });
            }}
          >
            <strong>
              User have been added but some have duplicate records.
              <br />
              Please click here to check the duplicate records
            </strong>
          </div>
        ) : (
          ""
        )}

        {isSuccess == true ? (
          <div
            className="alert alert-success alert-dismissible fade show mb-0 d-flex justify-content-center align-items-center"
            role="alert"
          >
            <img src={CHECKED_GREEN} alt="checked-green" className="me-2" />
            <strong>The corporate users have been created successfully</strong>
            <button
              type="button"
              class="btn-close"
              onClick={() =>
                this.setState({
                  isSuccess: false,
                })
              }
            ></button>
          </div>
        ) : (
          ""
        )}

        <div>
          <div className="table-responsive">
            {userList.map((list) =>
              list.data.length > 0 ? (
                <table className="manage-table table table-striped">
                  <thead className="table-dark">
                    <tr>
                      <th className="text-center">SELECT</th>
                      <th>NAME</th>
                      <th>EMAIL &nbsp; ADDRESS</th>
                      <th>TIER</th>
                      <th>ROLE</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {filteredUsers.map((list) =>
                      list.data.length > 0 ? (
                        list.data.map((user) => {
                          return (
                            <tr key={user.id}>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id={user.id}
                                    name="userCheckbox"
                                    onChange={() => this.handleChecked(user)}
                                    checked={user.isChecked}
                                  />
                                  <label
                                    className="form-check-label"
                                    for={user.id}
                                  ></label>
                                </div>
                              </td>
                              <td className="font-gorita-medium">
                                {user.firstname + " " + user.lastname}
                              </td>
                              <td className="email-address">{user.email}</td>
                              <td>
                                {
                                  tiersList.find(
                                    (tier) => tier.id === user.tierid
                                  )?.tiername
                                }
                              </td>
                              <td className="roleName">
                                {user.type === "COMPANYREPRESENTATIVE"
                                  ? "Company Representative"
                                  : user.type.toLowerCase()}
                              </td>
                              <td className="text-center">
                                <a
                                  className="link redirect-link"
                                  onClick={() => {
                                    this.props.navigate(
                                      "/manage-users/user/edit",
                                      {
                                        state: {
                                          ...this.props.navigateState,
                                          selectedCompany:
                                            this.state.selectedCompany,
                                          user,
                                        },
                                      }
                                    );
                                  }}
                                >
                                  View Details
                                </a>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="5" className="text-center noData">
                            No user Found
                          </td>
                        </tr>
                      )
                    )}

                    {/* {filteredUsers.map((list) =>
                      list.data.map((user) => {
                        return (
                          <tr key={user.id}>
                            <td>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id={user.id}
                                  name="userCheckbox"
                                  onChange={() => this.handleChecked(user)}
                                  checked={user.isChecked}
                                />
                                <label
                                  className="form-check-label"
                                  for={user.id}
                                ></label>
                              </div>
                            </td>
                            <td className="font-gorita-medium">
                              {user.firstname + " " + user.lastname}
                            </td>
                            <td className="email-address">{user.email}</td>
                            <td>
                              {
                                tiersList.find(
                                  (tier) => tier.id === user.tierid
                                )?.tiername
                              }
                            </td>
                            <td className="roleName">
                              {user.type === "COMPANYREPRESENTATIVE"
                                ? "Company Representative"
                                : user.type.toLowerCase()}
                            </td>
                            <td className="text-center">
                              <a
                                className="link redirect-link"
                                onClick={() => {
                                  this.props.navigate(
                                    "/manage-users/user/edit",
                                    {
                                      state: {
                                        ...this.props.navigateState,
                                        selectedCompany:
                                          this.state.selectedCompany,
                                        user,
                                      },
                                    }
                                  );
                                }}
                              >
                                View Details
                              </a>
                            </td>
                          </tr>
                        );
                      })
                    )} */}
                  </tbody>
                </table>
              ) : (
                <div className="text-center noData">
                  There are no users added for this company
                </div>
              )
            )}
          </div>
          {userList.map((list) =>
            list.totalPages > 1 ? (
              <div className="pagination">
                <div>
                  <span>Page</span>{" "}
                  <span className="page-count">{list.currentPage}</span>of
                  <span className="total-page page-count">
                    {list.totalPages}
                  </span>
                </div>
                <div className="pagenation-arrow">
                  <span
                    className={`icon icon-prev ${
                      list.currentPage == 1 ? "disabled" : ""
                    }`}
                    onClick={this.goToPreviousUserPage}
                  ></span>{" "}
                  <span
                    className={`icon icon-next ${
                      list.totalPages == this.state.currentPage
                        ? "disabled"
                        : ""
                    }`}
                    onClick={this.goToNextUserPage}
                  ></span>
                </div>
              </div>
            ) : (
              ""
            )
          )}
        </div>

        {isLoading ? (
          <div className="loader-div">
            {" "}
            <LoadingSpinner />{" "}
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}

export default function TempFunction() {
  const { state } = useLocation();
  return <ViewUsers navigate={useNavigate()} navigateState={state} />;
}
