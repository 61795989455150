import $ from "jquery";
import React, { Component } from "react";
import "sweetalert2/src/sweetalert2.scss";
import * as API from "../../../../configuration/apiconfig";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import LoadingSpinner from "../../../LoadingSpinner";
import { INFO_ICON, WARNING_ICON } from "../../../../assets/index";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
export default class EditDeal extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      isSelected: false,
      isLoading: false,
      dealData: {
        id: "",
        name: "",
        arabicDealName: "",
        dealType: "",
        description: "",
        arabicDescription: "",
        offeritems: [
          {
            itemType: "",
            itemCode: "",
          },
        ],
        availabilityrules: [
          {
            offerDays: [],
          },
        ],
      },
      errors: {
        name: "",
        arabicDealName: "",
        description: "",
        arabicDescription: "",
      },
      itemCodeError: "",
      availabilityrulesError: "",
      weekday: [
        { id: 1, day: "Sun", isSelected: false },
        { id: 2, day: "Mon", isSelected: false },
        { id: 3, day: "Tue", isSelected: false },
        { id: 4, day: "Wed", isSelected: false },
        { id: 5, day: "Thu", isSelected: false },
        { id: 6, day: "Fri", isSelected: false },
        { id: 7, day: "Sat", isSelected: false },
      ],
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.selectedDeal !== this.props.selectedDeal) {
      this.setState({
        dealData: {
          id: this.props.selectedDeal.id,
          name: this.props.selectedDeal.name,
          arabicDealName: this.props.selectedDeal.altName,
          dealType: this.props.selectedDeal.dealType,
          description: this.props.selectedDeal.description,
          arabicDescription: this.props.selectedDeal.arabicDescription,
          offeritems: [
            {
              itemType: this.props.selectedDeal.dealType,
              itemCode: this.props.selectedDeal.offeritems
                .map((item) => item.itemCode)
                .toString(),
            },
          ],
          availabilityrules: [
            {
              offerDays: this.props.selectedDeal.availabilityrules[0].offerDays,
            },
          ],
        },
        weekday: this.state.weekday.map((day) => {
          if (
            this.props.selectedDeal.availabilityrules[0].offerDays.includes(
              day.id
            )
          ) {
            return { ...day, isSelected: true };
          } else {
            return day;
          }
        }),
      });
    }
  }

  validate = (name, value) => {
    switch (name) {
      case "name":
        if (!value || value.trim() === "") {
          return "Please enter deal name";
        } else {
          return "";
        }
      case "arabicDealName":
        if (!value || value.trim() === "") {
          return "Please enter arabic deal name";
        } else {
          return "";
        }
      case "description":
        if (!value || value.trim() === "") {
          return "Please enter description";
        } else {
          return "";
        }
      case "arabicDescription":
        if (!value || value.trim() === "") {
          return "Please enter arabic description";
        } else {
          return "";
        }

      default: {
        return "";
      }
    }
  };

  handleValueChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: this.validate(name, value),
      },
      dealData: {
        ...this.state.dealData,
        [name]: value,
      },
    });
    if (name === "itemCode") {
      if (this.state.dealData.offeritems.length === 0 || !value) {
        if (this.state.dealData.dealType === "ticket") {
          this.setState({
            itemCodeError: "Please enter head office ticket type code",
          });
        } else {
          this.setState({
            itemCodeError: "Please enter F&B Promo Code",
          });
        }
      } else {
        this.setState({
          itemCodeError: "",
        });
      }
      this.setState({
        dealData: {
          ...this.state.dealData,
          offeritems: [
            {
              ...this.state.dealData.offeritems,
              [name]: value,
            },
          ],
        },
      });
    }
  };

  handleDay = (row) => {
    this.state.weekday.find((day) => day.id === row.id).isSelected =
      !row.isSelected;
    const selectedDay = this.state.weekday.find((day) => day.id === row.id);

    this.setState({
      dealData: {
        ...this.state.dealData,
        availabilityrules: [
          {
            offerDays: [
              ...this.state.dealData.availabilityrules[0].offerDays,
              selectedDay.id,
            ],
          },
        ],
      },
    });

    if (
      this.state.dealData.availabilityrules[0].offerDays.includes(
        selectedDay.id
      )
    ) {
      const index =
        this.state.dealData.availabilityrules[0].offerDays.findIndex((num) => {
          return num === selectedDay.id;
        });

      this.state.dealData.availabilityrules[0].offerDays.splice(index, 1);
      this.setState({
        dealData: {
          ...this.state.dealData,
          availabilityrules: [
            {
              offerDays: [
                ...this.state.dealData.availabilityrules[0].offerDays,
              ],
            },
          ],
        },
      });
    }

    if (
      this.state.dealData.availabilityrules[0].offerDays.length === 0 &&
      row.isSelected === false
    ) {
      this.setState({
        availabilityrulesError: "Please select deal date",
      });
    } else {
      this.setState({
        availabilityrulesError: "",
      });
    }
  };

  handleModalClose = () => {
    this.setState({
      errors: {},
      availabilityrulesError: "",
      itemCodeError: "",
    });
    this.props.handleClose();
    this.setState({
      weekday: [
        { id: 1, day: "Sun", isSelected: false },
        { id: 2, day: "Mon", isSelected: false },
        { id: 3, day: "Tue", isSelected: false },
        { id: 4, day: "Wed", isSelected: false },
        { id: 5, day: "Thu", isSelected: false },
        { id: 6, day: "Fri", isSelected: false },
        { id: 7, day: "Sat", isSelected: false },
      ],
    });
  };

  handleEditDeal = async (e) => {
    const { dealData } = this.state;
    e.preventDefault();
    let validationErrors = {};

    if (dealData.availabilityrules[0].offerDays.length === 0) {
      this.setState({
        availabilityrulesError: "Please select deal date",
      });
    }

    if (dealData.offeritems.itemCode == "") {
      this.setState({
        itemCodeError: "Please enter head office ticket type code",
      });
    }

    Object.keys(dealData).forEach((name) => {
      const error = this.validate(name, dealData[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      this.setState({ errors: validationErrors });
      return;
    }

    if (
      dealData.name &&
      dealData.arabicDealName &&
      dealData.description &&
      dealData.arabicDescription &&
      dealData.offeritems.map((item) => item.itemCode) &&
      dealData.availabilityrules[0].offerDays.length > 0
    ) {
      this.setState({
        isLoading: true,
      });

      const offerItemStringToArray = this.state.dealData.offeritems.flatMap(
        (item) => item.itemCode.split(",")
      );

      const offerItemArrayToObj = offerItemStringToArray.map((item) => ({
        itemType: this.props.selectedDeal.dealType,
        itemCode: item,
      }));

      const requestBody = {
        id: this.props.selectedDeal.id,
        name: this.state.dealData.name,
        altName: this.state.dealData.arabicDealName,
        dealType: this.props.selectedDeal.dealType,
        description: this.state.dealData.description,
        arabicDescription: this.state.dealData.arabicDescription,
        offeritems: offerItemArrayToObj,
        availabilityrules: {
          offerDays: this.state.dealData.availabilityrules[0].offerDays,
        },
      };
      const getDealsApiResponse = await API.callEndpoint(
        "PUT",
        "Bearer",
        `/api/companies/${this.props.companyId}/tiers/${this.props.tierId}/deals`,
        requestBody
      ).then((response) => {
        this.setState({
          isLoading: false,
        });
        Swal.fire({
          icon: "success",
          title: "Deal updated successfully",
          showConfirmButton: true,
          confirmButtonColor: "#e51937",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.props.handleClose();
            this.setState({
              dealData: {
                id: "",
                name: "",
                dealType: "",
                description: "",
                offeritems: [
                  {
                    itemType: "",
                    itemCode: "",
                  },
                ],
                availabilityrules: [
                  {
                    offerDays: [],
                  },
                ],
              },
              weekday: [
                { id: 1, day: "Sun", isSelected: false },
                { id: 2, day: "Mon", isSelected: false },
                { id: 3, day: "Tue", isSelected: false },
                { id: 4, day: "Wed", isSelected: false },
                { id: 5, day: "Thu", isSelected: false },
                { id: 6, day: "Fri", isSelected: false },
                { id: 7, day: "Sat", isSelected: false },
              ],
            });
          }
        });
        return response.data;
      });
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    var { isLoading, errors, availabilityrulesError, itemCodeError } =
      this.state;
    return (
      <div>
        {this.props.showEditModal && (
          <div className="add-deal-modal">
            <div className="overlay"></div>
            <div
              className={`modal fade deal-modal ${
                this.props.showEditModal ? "show" : "close-modal"
              }`}
              id="addModal"
              tabindex="-1"
              aria-labelledby="addDealModal"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title" id="addDealModal">
                      Edit{" "}
                      {this.props.selectedDeal.dealType === "ticket"
                        ? "Ticket"
                        : "F & B"}{" "}
                      Deal
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={this.handleModalClose}
                    ></button>
                  </div>
                  <div className="modal-body company-input-form">
                    <div className="row">
                      <div className="col-6 d-flex flex-column justify-content-between">
                        <div className="field">
                          <label className="form-label">Deal Name</label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            value={this.state.dealData.name}
                            placeholder="Deal Name"
                            className={`form-control ${
                              errors.name ? "input-error" : ""
                            }`}
                            onChange={this.handleValueChange}
                          />
                          {errors.name && (
                            <div className="error-text d-flex">
                              <img
                                src={WARNING_ICON}
                                alt="warning"
                                className="me-1"
                              />
                              <span>{errors.name}</span>
                            </div>
                          )}
                        </div>
                        <div className="field">
                          <label className="form-label">
                            Deal Name (Arabic) *
                          </label>
                          <input
                            type="text"
                            id="arabicDealName"
                            name="arabicDealName"
                            value={this.state.dealData.arabicDealName}
                            placeholder="Deal Name (Arabic) *"
                            className={`form-control ${
                              errors.arabicDealName ? "input-error" : ""
                            }`}
                            onChange={this.handleValueChange}
                          />
                          {errors.arabicDealName && (
                            <div className="error-text d-flex">
                              <img
                                src={WARNING_ICON}
                                alt="warning"
                                className="me-1"
                              />
                              <span>{errors.arabicDealName}</span>
                            </div>
                          )}
                        </div>
                        <div className="field">
                          <label className="form-label">
                            {this.props.selectedDeal.dealType === "ticket"
                              ? "Head Office Code "
                              : "F&B Promo Code "}
                            <img
                              src={INFO_ICON}
                              alt="info"
                              className="info-icon"
                              id="app-title"
                            />
                            <ReactTooltip
                              anchorId="app-title"
                              place="right"
                              content={
                                this.props.selectedDeal.dealType === "ticket"
                                  ? "Kindly enter Head Office Code configured for corporate users in Vista"
                                  : " Kindly enter F&B Promo Code configured for corporate users in Vista"
                              }
                            />
                          </label>

                          <input
                            type="text"
                            id="itemCode"
                            name="itemCode"
                            value={this.state.dealData.offeritems
                              .map((item) => item.itemCode)
                              .toString()}
                            placeholder="Enter the Code"
                            className={`form-control ${
                              itemCodeError ? "input-error" : ""
                            }`}
                            onChange={this.handleValueChange}
                          />
                          {itemCodeError && (
                            <div className="error-text d-flex">
                              <img
                                src={WARNING_ICON}
                                alt="warning"
                                className="me-1"
                              />
                              <span>{itemCodeError}</span>
                            </div>
                          )}
                        </div>
                        <div className="field">
                          <label className="form-label">Availability</label>
                          <div className="day-badges">
                            {this.state.weekday.map((week) => (
                              <div
                                id={week.id}
                                className={`badge ${
                                  week.isSelected ? "selected-day" : ""
                                }`}
                                onClick={() => this.handleDay(week)}
                              >
                                {week.day}
                              </div>
                            ))}
                          </div>
                          {availabilityrulesError && (
                            <div className="error-text d-flex">
                              <img
                                src={WARNING_ICON}
                                alt="warning"
                                className="me-1"
                              />
                              <span>{availabilityrulesError}</span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-6 h-100">
                        <div className="field">
                          <label className="form-label">
                            Description (English) *
                          </label>
                          <textarea
                            className={`form-control textarea ${
                              errors.description ? "input-error" : ""
                            }`}
                            placeholder="Text here..."
                            id="floatingTextarea2"
                            rows="3"
                            name="description"
                            value={this.state.dealData.description}
                            onChange={this.handleValueChange}
                          ></textarea>
                          {errors.description && (
                            <div className="error-text d-flex">
                              <img
                                src={WARNING_ICON}
                                alt="warning"
                                className="me-1"
                              />
                              <span>{errors.description}</span>
                            </div>
                          )}
                        </div>
                        <div className="field">
                          <label className="form-label">
                            Description (Arabic)*
                          </label>
                          <textarea
                            className={`form-control textarea ${
                              errors.arabicDescription ? "input-error" : ""
                            }`}
                            placeholder="Text here..."
                            id="arabicDescription"
                            rows="3"
                            name="arabicDescription"
                            value={this.state.dealData.arabicDescription}
                            onChange={this.handleValueChange}
                          ></textarea>
                          {errors.arabicDescription && (
                            <div className="error-text d-flex">
                              <img
                                src={WARNING_ICON}
                                alt="warning"
                                className="me-1"
                              />
                              <span>{errors.arabicDescription}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer center-button">
                    <div className="btnWrap">
                      <button
                        className="btn btn-black btn-small"
                        onClick={this.handleEditDeal}
                      >
                        <span className="icon-add"></span>Edit Deal
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isLoading ? (
          <div className="loader-div">
            {" "}
            <LoadingSpinner />{" "}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

// export default function TempFunction(props) {
//   return <EditDeal {...props} />;
// }
