import React, { Component } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import * as API from "../../../../configuration/apiconfig";
import Heading from "../../Heading";
import LoadingSpinner from "../../../LoadingSpinner";
import { EYEOPEN, EYECLOSE, WARNING_ICON } from "../../../../assets";

class AddSingleUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      tiersList: [],
      userType: localStorage.getItem("type"),
      userTypeList: [
        {
          id: 1,
          type: "Company Representative",
        },
        {
          id: 2,
          type: "Employee",
        },
      ],
      selectedTier: {},
      selectedUser: {},
      tierError: "",
      userTypeError: "",
      showPassword: false,
      showConfirmPassword: false,
      userData: {
        firstname: "",
        lastname: "",
        email: "",
        // password: "",
        // confirmPassword: "",
        phonenumber: "",
      },
      errors: {
        firstname: "",
        lastname: "",
        email: "",
        // password: "",
        // confirmPassword: "",
        phonenumber: "",
      },
    };
  }

  componentDidMount = async () => {
    const tierData = await API.callEndpoint(
      "GET",
      "Bearer",
      `/api/companies/${this.props.navigateState.id}/tiers`
    ).then((response) => {
      return response.data;
    });
    this.setState({
      ...this.state,
      tiersList: tierData,
    });
  };

  setValue = (tierObj) => {
    this.setState({
      selectedTier: tierObj,
      tierError: "",
    });
  };

  setUser = (userObj) => {
    this.setState({
      selectedUser: userObj,
      userTypeError: "",
    });
  };

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  };

  validate = (name, value) => {
    const { userData } = this.state;
    switch (name) {
      case "firstname":
        if (!value || value.trim() === "") {
          return " Please enter the first name.";
        } else {
          return "";
        }
      case "lastname":
        if (!value || value.trim() === "") {
          return "Please enter the last name.";
        } else {
          return "";
        }
      case "email":
        var splitedArray = value.split("@");
        const emailHandleSplit = this.props.navigateState.emailHandle.flatMap(
          (email) => {
            return email.split("@");
          }
        );

        if (!value) {
          return "Please enter a valid email address matching the company domain.";
        } else if (
          !value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/) ||
          !emailHandleSplit.includes(splitedArray[1])
        ) {
          return "Please enter a valid email address matching the company domain.";
        } else {
          return "";
        }
      case "phonenumber":
        if (value && !value.match(/^(05)\d{8}$/)) {
          return "Please enter a valid mobile number starting with '05'";
        } else {
          return "";
        }
      // case "password":
      //   if (!value) {
      //     return "Please enter password";
      //   } else {
      //     return "";
      //   }
      // case "confirmPassword":
      //   if (!value) {
      //     return "Please enter confirm password";
      //   } else if (value !== this.state.userData.password) {
      //     return "Password don't match";
      //   } else {
      //     return "";
      //   }
      default: {
        return "";
      }
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: this.validate(name, value),
      },
      userData: {
        ...this.state.userData,
        [name]: value,
      },
    });
  };

  addUser = async (data) => {
    this.setState({
      isLoading: true,
    });
    await API.callEndpoint("POST", "Bearer", "/api/users/register", data)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "User added successfully",
          showConfirmButton: true,
          confirmButtonColor: "#e51937",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.props.navigate("/manage-users/users", {
              state: {
                ...this.props.navigateState,
              },
            });
          }
        });
        return response.data;
      })
      .catch((a) => {
        const errorMessage = a.error;
        this.setState({
          isLoading: true,
        });
        Swal.fire({
          icon: "error",
          title: errorMessage,
          allowOutsideClick: false,
        });
      });

    this.setState({
      isLoading: false,
    });
  };

  handleSubmit = async (e) => {
    const { userData } = this.state;
    e.preventDefault();
    let validationErrors = {};

    if (!Object.keys(this.state.selectedTier).length) {
      this.setState({
        tierError: "Please select a tier type.",
      });
    }
    if (!Object.keys(this.state.selectedUser).length) {
      this.setState({
        userTypeError: "Please select a user type.",
      });
    }

    Object.keys(userData).forEach((name) => {
      const error = this.validate(name, userData[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      this.setState({ errors: validationErrors });
      return;
    }

    if (
      userData.firstname &&
      userData.lastname &&
      userData.email &&
      this.state.selectedTier.id &&
      this.state.selectedUser.type
    ) {
      const userInfo = {
        email: userData.email,
        type:
          this.state.selectedUser.type === "Company Representative"
            ? "COMPANYREPRESENTATIVE"
            : "EMPLOYEE",
        firstname: userData.firstname,
        lastname: userData.lastname,
        phonenumber: userData.phonenumber ? userData.phonenumber : "",
        companyid: this.props.navigateState.id,
        tierid: this.state.selectedTier.id,
      };
      this.addUser(userInfo);
    }
    // if (this.state.selectedUser.type == "Employee") {
    //   this.addUser(userInfo);
    // } else if (
    //   this.state.selectedUser.type == "Company Representative" &&
    //   this.props.navigateState.selectedCompany.crId
    // ) {
    //   Swal.fire({
    //     icon: "error",
    //     // title: `We already have Company Representative ${this.props.navigateState.selectedCompany.crEmail} and ${this.props.navigateState.selectedCompany.crFirstname} assigned for this company ${this.props.navigateState.selectedCompany.companyName}. Do you still want to proceed? `,
    //     html:
    //       `We already have Company Representative <b style='color:#e51937'>${this.props.navigateState.selectedCompany.crEmail} - </b>` +
    //       `<b style='color:#e51937'>${this.props.navigateState.selectedCompany.crFirstname} </b>` +
    //       `assigned for this company ${this.props.navigateState.selectedCompany.companyName}.` +
    //       `<div style='margin-top:25px'>Do you still want to proceed?</div>`,
    //     showConfirmButton: true,
    //     showCancelButton: true,
    //     confirmButtonColor: "#e51937",
    //     allowOutsideClick: false,
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       this.addUser(userInfo);
    //     }
    //   });
    // } else if (
    //   this.state.selectedUser.type == "Company Representative" &&
    //   this.props.navigateState.selectedCompany.crId == null
    // ) {
    //   this.addUser(userInfo);
    // }
  };

  render() {
    const {
      errors,
      isLoading,
      userData,
      tiersList,
      userTypeList,
      selectedTier,
      tierError,
      selectedUser,
      userTypeError,
    } = this.state;
    const userType = localStorage.getItem("type");
    return (
      <section className="addCompanyWrapper">
        <Heading title="Add Single User" />

        <nav>
          <ul>
            <li>
              <a
                className="link-added"
                onClick={() => {
                  this.props.navigate("/dashboard");
                }}
              >
                Home
              </a>
            </li>
            <li>
              <a
                className="link link-added"
                onClick={() => {
                  this.props.navigate("/manage-users");
                }}
              >
                User Management
              </a>
            </li>
            <li>
              <a
                className="link link-added"
                onClick={() => {
                  this.props.navigate("/manage-users/users", {
                    state: this.props.navigateState,
                  });
                }}
              >
                {this.props.navigateState.companyName}
              </a>
            </li>
            <li>
              <a className="link link-added">Add User</a>
            </li>
          </ul>
        </nav>

        <div className="company-input-form">
          <a
            href="javascript:;"
            className="back-link"
            onClick={() => {
              this.props.navigate("/manage-users/users", {
                state: this.props.navigateState,
              });
            }}
          >
            Back
          </a>
          <div className="row">
            <div className="col-5 mb-5">
              <div className="form-floating">
                <input
                  type="text"
                  class={`form-control ${
                    errors.firstname ? "input-error" : ""
                  }`}
                  id="firstname"
                  placeholder="Firstname"
                  name="firstname"
                  value={userData.firstname}
                  onChange={this.handleChange}
                />
                <label htmlFor="firstname">Firstname*</label>
              </div>
              {errors.firstname && (
                <div className="error-text d-flex">
                  <img src={WARNING_ICON} alt="warning" className="me-1" />
                  <span>{errors.firstname}</span>
                </div>
              )}
            </div>

            <div className="col-5 mb-5">
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control ${
                    errors.lastname ? "input-error" : ""
                  }`}
                  id="lastname"
                  placeholder="Lastname"
                  name="lastname"
                  value={userData.lastname}
                  onChange={this.handleChange}
                />
                <label htmlFor="lastname">Lastname*</label>
              </div>
              {errors.lastname && (
                <div className="error-text d-flex">
                  <img src={WARNING_ICON} alt="warning" className="me-1" />
                  <span>{errors.lastname}</span>
                </div>
              )}
            </div>

            <div className="col-5 mb-5">
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control noCapitalize ${
                    errors.email ? "input-error" : ""
                  }`}
                  id="email"
                  placeholder="Email Address"
                  name="email"
                  value={userData.email}
                  onChange={this.handleChange}
                />
                <label htmlFor="email">Email Address*</label>
              </div>
              {errors.email && (
                <div className="error-text d-flex">
                  <img src={WARNING_ICON} alt="warning" className="me-1" />
                  <span>{errors.email}</span>
                </div>
              )}
            </div>

            <div className="col-5 mb-5">
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control ${
                    errors.phonenumber ? "input-error" : ""
                  }`}
                  id="phonenumber"
                  placeholder="Phone Number"
                  name="phonenumber"
                  value={userData.phonenumber}
                  onChange={this.handleChange}
                  maxlength="10"
                />

                <label htmlFor="phonenumber">Phone Number</label>
              </div>

              {/* <div class="input-group mb-3">
                <span class="input-group-text">05</span>
                <div class="form-floating">
                  <input
                    type="text"
                    className={`form-control ${
                      errors.phonenumber ? "input-error" : ""
                    }`}
                    id="phonenumber"
                    placeholder="Phone Number"
                    name="phonenumber"
                    value={userData.phonenumber}
                    onChange={this.handleChange}
                    maxlength="10"
                  />
                  <label htmlFor="phonenumber">Phone Number*</label>
                </div>
              </div> */}

              {errors.phonenumber && (
                <div className="error-text d-flex">
                  <img src={WARNING_ICON} alt="warning" className="me-1" />
                  <span>{errors.phonenumber}</span>
                </div>
              )}
            </div>

            {/* <div className="col-5 mb-5">
              <div className="form-floating enter-password">
                <input
                  type={this.state.showPassword ? "text" : "password"}
                  className="form-control"
                  id="password"
                  placeholder="Enter Password"
                  name="password"
                  value={userData.password}
                  onChange={this.handleChange}
                />
                <span
                  className="eye-icon"
                  id="addon-wrapping"
                  onClick={this.handleClickShowPassword}
                >
                  <img
                    src={this.state.showPassword ? EYECLOSE : EYEOPEN}
                    alt="eye-icon"
                  />
                </span>
                <label htmlFor="password">Enter Password</label>
              </div>
            </div>

            <div className="col-5 mb-5">
              <div className="form-floating confirm-password">
                <input
                  type={this.state.showConfirmPassword ? "text" : "password"}
                  className="form-control"
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  value={userData.confirmPassword}
                  onChange={this.handleChange}
                />
                <span
                  className="eye-icon"
                  id="addon-wrapping"
                  onClick={this.handleClickShowConfirmPassword}
                >
                  <img
                    src={this.state.showConfirmPassword ? EYECLOSE : EYEOPEN}
                    alt="eye-icon"
                  />
                </span>
                <label htmlFor="confirmPassword">Confirm Password</label>
              </div>
            </div> */}

            <div className="col-5 mb-5 mt-5">
              <div className="dropdown">
                <label htmlFor="tiertype" className="form-label dropdown-label">
                  Tier Type*
                </label>
                <button
                  htmlFor="tiertype"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  className={`btn btn-secondary dropdown-toggle ${
                    tierError ? "input-error" : ""
                  }`}
                >
                  {selectedTier.tiername
                    ? selectedTier.tiername
                    : "Select a Tier"}
                </button>
                <ul
                  className={`dropdown-menu ${tierError === "" ? "hide" : ""}`}
                >
                  {tiersList.map((tier) => {
                    return (
                      <li>
                        <a
                          className="dropdown-item"
                          href="javascript:;"
                          onClick={() => this.setValue(tier)}
                        >
                          {tier.tiername}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {tierError && (
                <div className="error-text d-flex">
                  <img src={WARNING_ICON} alt="warning" className="me-1" />
                  <span>{tierError}</span>
                </div>
              )}
            </div>

            {this.state.userType === "SUPERADMIN" ||
            this.state.userType === "ADMIN" ||
            this.state.userType === "COMPANYREPRESENTATIVE" ? (
              <div className="col-5 mb-5 mt-5">
                <div className="dropdown">
                  <label
                    htmlFor="userType"
                    className="form-label dropdown-label"
                  >
                    User Type*
                  </label>
                  <button
                    htmlFor="userType"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className={`btn btn-secondary dropdown-toggle ${
                      userTypeError ? "input-error" : ""
                    }`}
                  >
                    {selectedUser.type ? selectedUser.type : "Select a type"}
                  </button>
                  {(this.state.userType === "SUPERADMIN" ||
                    this.state.userType === "ADMIN") && (
                    <ul
                      className={`dropdown-menu ${
                        userTypeError === "" ? "hide" : ""
                      }`}
                    >
                      {userTypeList.map((user) => {
                        return (
                          <li>
                            <a
                              className="dropdown-item"
                              href="javascript:;"
                              onClick={() => this.setUser(user)}
                            >
                              {user.type}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  )}

                  {this.state.userType === "COMPANYREPRESENTATIVE" && (
                    <ul
                      className={`dropdown-menu ${
                        userTypeError === "" ? "hide" : ""
                      }`}
                    >
                      <li>
                        <a
                          className="dropdown-item"
                          href="javascript:;"
                          onClick={() =>
                            this.setUser(this.state.userTypeList[1])
                          }
                        >
                          {userTypeList[1].type}
                        </a>
                      </li>
                    </ul>
                  )}
                </div>
                {userTypeError && (
                  <div className="error-text d-flex">
                    <img src={WARNING_ICON} alt="warning" className="me-1" />
                    <span>{userTypeError}</span>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}

            <div className="btnWrap">
              <button
                className="btn btn-primary btn-small"
                onClick={this.handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="loader-div">
            {" "}
            <LoadingSpinner />{" "}
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}

export default function TempFunction(props) {
  const { state } = useLocation();
  return <AddSingleUser navigate={useNavigate()} navigateState={state} />;
}
