import React, {Component} from "react";
import {Link, useNavigate} from "react-router-dom";
import * as API from "../../../configuration/apiconfig";
import Heading from "../Heading";
import LoadingSpinner from "../../LoadingSpinner";

class ManageTiers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companiesList: [],
    };
  }

  componentDidMount = async () => {
    this.setState({
      isLoading: true,
    });
    const responseData = await API.callEndpoint("GET", "Bearer", "/api/companies").then((response) => {
      return response.data;
    });
    this.setState({
      companiesList: responseData,
      isLoading: false,
    });
  };

  render() {
    const {companiesList, isLoading} = this.state;
    return (
      <section className="companiesWrapper">
        <Heading title="Manage Tiers" />
        <nav>
          <ul>
            <li>
              <a
                className="link-added"
                onClick={() => {
                  this.props.navigate("/dashboard");
                }}
              >
                Home
              </a>
            </li>
            <li>
              <a className="link-added">Manage Tiers</a>
            </li>
          </ul>
        </nav>
        <div>
          <div className="table-responsive">
            <table className="manage-table table table-striped table-more-space">
              <thead className="table-dark">
                <tr>
                  <th>COMPANY NAME</th>
                  <th>NO.OF TIERS</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {companiesList.map((company) => {
                  return (
                    <tr>
                      <td className="font-gorita-medium">
                        <span>{company.companyName}</span>
                      </td>
                      <td>{company.tiersCount}</td>
                      <td className="text-center">
                        <a
                          href="javascript:;"
                          className="link"
                          onClick={() => {
                            this.props.navigate("/manage-tiers/tiers", {
                              state: company,
                            });
                          }}
                        >
                          View Tiers
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <div className="pagination">
            <div>
              <span className="page-count">1-8</span>of
              <span className="total-page">25</span>
            </div>
            <div className="pagenation-arrow">
              <span className="icon icon-prev"></span>{" "}
              <span className="icon icon-next"></span>
            </div>
          </div> */}
        </div>
        {isLoading ? (
          <div className="loader-div">
            {" "}
            <LoadingSpinner />{" "}
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}

export default function TempFunction(props) {
  return <ManageTiers navigate={useNavigate()} />;
}
