import moment from "moment";
import React, { Component } from "react";
import AmcLogo from "../../../assets/images/amc-logo-red.png";
import Calendar from "../../../assets/images/Calendar.svg";

export default class DashBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownVisible: false,
    };
  }

  hadleSignOut = () => {
    this.state = {
      isDropdownVisible: false,
    };
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }

    localStorage.removeItem("token");
    localStorage.removeItem("adminname");
    localStorage.removeItem("type");

    window.location.href = "/";
  };

  handleOpenDropdown = () => {
    this.setState({
      isDropdownVisible: !this.state.isDropdownVisible,
    });
  };

  render() {
    const username = localStorage.getItem("adminname");
    const { isDropdownVisible } = this.state;

    return (
      <header>
        <section className="container-fluid">
          <section className="row">
            <section className="col-xl-2 col-lg-2 col-md-3 logo-wrapper">
              <figure>
                <a href="javascript:;" title="AMC Cinemas">
                  <img
                    src={AmcLogo}
                    alt="AMC Cinemas"
                    className="amc-cinema-logo"
                  />
                </a>
              </figure>
            </section>
            <section className="col-xl-10 col-lg-10 col-md-9 col-right">
              <section className="user-info">
                <div className="calender-sec">
                  <span>
                    <img
                      src={Calendar}
                      alt="Calender"
                      className="icon calender-icon"
                    />
                  </span>
                  <span>{moment().format("DD MMMM, YYYY")}</span>
                </div>
                <div className="btnWrap">
                  {!isDropdownVisible && (
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        onClick={this.handleOpenDropdown}
                      >
                        <span className="user-img"></span>
                        <span>{username}</span>
                        <span className="arrow top"></span>
                      </button>
                    </div>
                  )}

                  {isDropdownVisible && (
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle show"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="true"
                        onClick={this.handleOpenDropdown}
                      >
                        <span className="user-img"></span>
                        <span>{username}</span>
                        <span className="arrow down"></span>
                      </button>
                      <ul
                        className="dropdown-menu show"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={this.hadleSignOut}
                          >
                            Sign Out
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </section>
            </section>
          </section>
        </section>
      </header>
    );
  }
}
