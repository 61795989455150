import addMonths from "date-fns/addMonths";
import endOfMonth from "date-fns/endOfMonth";
import startOfMonth from "date-fns/startOfMonth";
import subDays from "date-fns/subDays";
import moment from "moment";
import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import * as API from "../../../configuration/apiconfig";
import LoadingSpinner from "../../LoadingSpinner";
import Heading from "../Heading";
import $ from "jquery";
import isAfter from "date-fns/isAfter";
const predefinedRanges = [
  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
  },
  {
    label: "Last 30 days",
    value: [subDays(new Date(), 29), new Date()],
  },
  {
    label: "Last month",
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
  },
];

const monthArray = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const {
  allowedMaxDays,
  allowedDays,
  allowedRange,
  beforeToday,
  afterToday,
  combine,
} = DateRangePicker;
class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isTableVisible: false,
      companiesList: [],
      tiersList: [],
      reportsType: [
        {
          id: 1,
          type: "User Reports",
        },
        {
          id: 2,
          type: "Ticket Usage Reports",
        },
        {
          id: 3,
          type: "Executive reports",
        },
      ],
      selectedCompany: {},
      selectedTier: {},
      selectedReportType: {},
      date: "",
      selectedDate: {
        from: "",
        to: "",
      },
      userReportsArray: [],
      ticketUsageReports: [],
      executiveReports: [],
      totalPages: 0,
      currentPage: 1,
      userReportsCsvData: [],
      ticketUsageReportsCsvData: [],
      executiveReportsCsvData: [],
      dateRangeValue: [],
      selectedValues: {
        reportType: "",
        company: {
          id: "",
          companyName: "",
        },
        date: {
          from: "",
          to: "",
        },
        tier: {
          id: "",
          tiername: "",
        },
      },
    };
  }

  getCompanies = async () => {
    this.setState({
      isLoading: true,
    });
    const responseData = await API.callEndpoint(
      "GET",
      "Bearer",
      "/api/companies"
    ).then((response) => {
      return response.data;
    });
    this.setState({
      companiesList: responseData,
      isLoading: false,
    });
  };

  componentDidMount = async () => {
    await this.getCompanies();
  };

  handleSelectedCompanyfromList = async (companyObj) => {
    if (this.state.selectedCompany.companyName) {
      this.setState({
        selectedTier: {},
      });
    }

    await this.setState({
      selectedCompany: companyObj,
    });

    // Get tierslist from selected company
    const tierData = await API.callEndpoint(
      "GET",
      "Bearer",
      `/api/companies/${this.state.selectedCompany.id}/tiers`
    ).then((response) => {
      return response.data;
    });

    await this.setState({
      tiersList: tierData,
    });
  };

  handleSelectedTierFromList = (tierObj) => {
    this.setState({
      selectedTier: tierObj,
    });
  };

  handleReportTypeFromList = (reportObj) => {
    this.setState({
      selectedReportType: reportObj,
      selectedCompany: {},
      selectedDate: {
        from: "",
        to: "",
      },
      selectedTier: {},
      dateRangeValue: [],
      currentPage: 1,
    });

    if (this.state.isTableVisible == true) {
      this.setState({
        isTableVisible: false,
      });
    }
  };

  filtereDateRange = (range) => {
    const filtered = range.map((dateValue) => {
      const dateString = dateValue.toString();
      var date = new Date(dateString.replace("IST", ""));

      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let month =
        date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      let year = date.getFullYear();

      return year + "/" + month + "/" + day;
    });

    this.setState({
      selectedDate: {
        from: filtered[0],
        to: filtered[1],
      },
      dateRangeValue: range,
    });
  };

  handleDateSelect = (range) => {
    this.filtereDateRange(range);
  };

  handleOnChange = (range) => {
    if (this.state.selectedDate.from && this.state.selectedDate.to) {
      this.setState({
        selectedDate: {
          from: "",
          to: "",
        },
        dateRangeValue: [],
      });
    }

    this.filtereDateRange(range);
  };

  handleValueClean = () => {
    this.setState(
      {
        dateRangeValue: [],
        selectedDate: {
          from: "",
          to: "",
        },
      },
      () => {
        console.log(this.state.dateRangeValue);
      }
    );
  };

  handleAllCompanies = (name) => {
    this.setState({
      selectedCompany: {
        companyName: name,
      },
      selectedTier: {},
    });
  };

  handleAllTiers = (name) => {
    this.setState({
      selectedTier: {
        tiername: name,
      },
    });
  };

  downloadFile = (csv, fileName) => {
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";

    //  provide the name for the CSV file to be downloaded
    hiddenElement.download = fileName;
    hiddenElement.click();
  };

  getUserReportsCsvData = async () => {
    const csvData = await API.callEndpoint(
      "POST",
      "Bearer",
      "api/reports/userreports/download",
      {
        companyId: this.state.selectedValues.company.id,
        tierid: this.state.selectedValues.tier.id,
        from: this.state.selectedValues.date.from,
        to: this.state.selectedValues.date.to,
      }
    ).then((response) => {
      return response.data;
    });

    this.downloadFile(csvData, "Userreports.csv");
  };

  getTicketUsageReportsCsvData = async () => {
    const csvData = await API.callEndpoint(
      "POST",
      "Bearer",
      "api/reports/ticketusagereports/download",
      {
        companyId: this.state.selectedValues.company.id,
        tierid: this.state.selectedValues.tier.id,
        from: this.state.selectedValues.date.from,
        to: this.state.selectedValues.date.to,
      }
    ).then((response) => {
      return response.data;
    });

    this.downloadFile(csvData, "Ticketusagereports.csv");
  };

  getExecutiveReportsCsvData = async () => {
    const csvData = await API.callEndpoint(
      "POST",
      "Bearer",
      "api/reports/executivereports/download",
      {
        companyId: this.state.selectedValues.company.id,
        from: this.state.selectedValues.date.from,
        to: this.state.selectedValues.date.to,
      }
    ).then((response) => {
      return response.data;
    });

    this.downloadFile(csvData, "Executivereports.csv");
  };

  getUserReports = async (data) => {
    const userReportsApiResponse = await API.callEndpoint(
      "POST",
      "Bearer",
      "api/reports/userreports",
      data
    ).then((response) => {
      return response.data;
    });
    this.setState({
      userReportsArray: userReportsApiResponse,
    });
  };

  getTicketUsageReports = async (data) => {
    const ticketUsageReportsApiResponse = await API.callEndpoint(
      "POST",
      "Bearer",
      "api/reports/ticketusagereports",
      data
    ).then((response) => {
      return response.data;
    });
    this.setState({
      ticketUsageReports: ticketUsageReportsApiResponse,
    });
  };

  getExecutiveReports = async (data) => {
    const executiveReportsApiResponse = await API.callEndpoint(
      "POST",
      "Bearer",
      "api/reports/executivereports",
      data
    ).then((response) => {
      return response.data;
    });
    this.setState({
      executiveReports: executiveReportsApiResponse,
    });
  };

  handleApplyFilter = async () => {
    await this.setState({
      selectedValues: {
        reportType: this.state.selectedReportType,
        company: {
          id: this.state.selectedCompany.id,
          companyName: this.state.selectedCompany.companyName,
        },
        date: {
          from: this.state.selectedDate.from,
          to: this.state.selectedDate.to,
        },
        tier: {
          id: this.state.selectedTier.id,
          tiername: this.state.selectedTier.tiername,
        },
      },
      currentPage: 1,
    });

    if (
      (this.state.selectedValues.company.companyName &&
        this.state.selectedValues.reportType.type &&
        this.state.selectedValues.date.from &&
        this.state.selectedValues.date.to) ||
      this.state.selectedValues.tier.tiername
    ) {
      const apiPayload = {
        page: 1,
        companyId: this.state.selectedValues.company.id,
        from: this.state.selectedValues.date.from,
        to: this.state.selectedValues.date.to,
        tierid: this.state.selectedValues.tier.id,
      };

      const allComapniesApiPayload = {
        page: 1,
        from: this.state.selectedValues.date.from,
        to: this.state.selectedValues.date.to,
      };

      if (this.state.selectedReportType.type == "User Reports") {
        await this.getUserReports(apiPayload);
      } else if (this.state.selectedReportType.type == "Ticket Usage Reports") {
        await this.getTicketUsageReports(apiPayload);
      } else if (
        this.state.selectedReportType.type == "Ticket Usage Reports" &&
        this.state.selectedCompany.companyName == "All Companies"
      ) {
        await this.getTicketUsageReports(allComapniesApiPayload);
      } else if (this.state.selectedReportType.type == "Executive reports") {
        await this.getExecutiveReports(apiPayload);
      } else if (
        this.state.selectedReportType.type == "Executive reports" &&
        this.state.selectedCompany.companyName == "All Companies"
      ) {
        await this.getExecutiveReports(allComapniesApiPayload);
      }
      this.setState({
        isTableVisible: true,
      });
    }
  };

  getReportsOnPagination = async (count) => {
    this.setState({
      currentPage: count,
    });

    const apiPayload = {
      page: count,
      companyId: this.state.selectedValues.company.id,
      from: this.state.selectedValues.date.from,
      to: this.state.selectedValues.date.to,
      tierid: this.state.selectedValues.tier.id,
    };

    if (this.state.selectedReportType.type == "User Reports") {
      await this.getUserReports(apiPayload);
    } else if (
      this.state.selectedReportType.type == "Ticket Usage Reports" ||
      (this.state.selectedReportType.type == "Ticket Usage Reports" &&
        this.state.selectedCompany.companyName == "All Companies")
    ) {
      await this.getTicketUsageReports(apiPayload);
    }
  };

  goToPreviousUserPage = async () => {
    let pageNo = this.state.currentPage - 1;
    await this.getReportsOnPagination(pageNo);
  };

  goToNextUserPage = async () => {
    let pageCount = this.state.currentPage + 1;
    await this.getReportsOnPagination(pageCount);
  };

  dateSuffix = (date) => {
    if (date > 3 && date < 21) return "th";
    switch (date % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  formatDate = (date) => {
    var showDate = date;
    var actualShowDate = showDate.split("T")[0].split("-");
    var date =
      actualShowDate[2] < 10
        ? actualShowDate[2].replace("0", "")
        : actualShowDate[2];

    var month =
      actualShowDate[1] < 10
        ? actualShowDate[1].replace("0", "")
        : actualShowDate[1];

    var year = actualShowDate[0];

    const monthName = monthArray[month - 1];

    return date + this.dateSuffix(date) + " " + monthName + " " + year;
  };

  formatTime = (time) => {
    var timeArray = time.split("T")[1].split(":");
    console.log('.....', timeArray[0]);
    // var hours =
    //   timeArray[0] > 12
    //     ? timeArray[0] - 12
    //     : (timeArray[0] = "00" ? 12 : timeArray[0]);
    var hours = 
      timeArray[0] < 12
        ? timeArray[0] == 0 ? 12 : timeArray[0]
        : timeArray[0] - 12
    var minutes = timeArray[1];
    var meridian = timeArray[0] > 12 ? "PM" : "AM";
    return hours + ":" + minutes + " " + meridian;
  };

  render() {
    const {
      isLoading,
      companiesList,
      tiersList,
      isTableVisible,
      selectedCompany,
      selectedTier,
      reportsType,
      selectedDate,
      selectedReportType,
      userReportsArray,
      ticketUsageReports,
      executiveReports,
    } = this.state;

    console.log(this.state.selectedDate.from, this.state.selectedDate.to);
    return (
      <section className="companiesWrapper">
        <div className="top-bar">
          <div className="left-sec">
            <Heading title="Reports" />
            <nav>
              <ul>
                <li>
                  <a
                    className="link-added"
                    onClick={() => {
                      this.props.navigate("/dashboard");
                    }}
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a className="link link-added">Reports</a>
                </li>
                {selectedReportType.type && (
                  <li>
                    <a className="link-added">{selectedReportType.type}</a>
                  </li>
                )}
              </ul>
            </nav>
          </div>
          <div className="right-sec report-dropdown-group m-0">
            <div className="sec-row p-0 fixedRightButton">
              <section className="row">
                <div className="cinemas-section company-input-form m-0 col-3">
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle report-drop"
                      type="button"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="true"
                      aria-expanded="false"
                    >
                      {selectedReportType.type
                        ? selectedReportType.type
                        : "Report type"}
                    </button>
                    <div className="dummy">
                      <ul className="dropdown-menu report-type-dropdown">
                        {reportsType.map((report) => {
                          return (
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={() =>
                                  this.handleReportTypeFromList(report)
                                }
                              >
                                <div className="form-check ps-0">
                                  {/* <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={report.id}
                                  checked={
                                    report.id == this.state.selectedReportType.id
                                  }
                                  onChange={() =>
                                    this.handleReportTypeFromList(report)
                                  }
                                /> */}
                                  <span
                                    className={`${
                                      report.id ==
                                      this.state.selectedReportType.id
                                        ? "active-selected"
                                        : ""
                                    }`}
                                  >
                                    {report.type}
                                  </span>
                                </div>
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className={`cinemas-section company-input-form m-0 col-3 ${
                    !selectedReportType.type ? "disabled-link" : ""
                  }`}
                >
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle report-drop"
                      type="button"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="true"
                      aria-expanded="false"
                    >
                      {selectedCompany.companyName
                        ? selectedCompany.companyName
                        : "Company *"}
                    </button>
                    <div className="dummy">
                      <ul className="dropdown-menu">
                        {selectedReportType.type == "Ticket Usage Reports" ||
                        selectedReportType.type == "Executive reports" ? (
                          <li>
                            <a className="dropdown-item">
                              <div
                                className={`form-check ps-0 ${
                                  this.state.selectedCompany.companyName ==
                                  "All Companies"
                                    ? "active-selected"
                                    : ""
                                }`}
                                value="All Companies"
                                onClick={() =>
                                  this.handleAllCompanies("All Companies")
                                }
                              >
                                All Companies
                              </div>
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {companiesList.map((company) => {
                          return (
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={() =>
                                  this.handleSelectedCompanyfromList(company)
                                }
                              >
                                <div className="form-check ps-0">
                                  {/* <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={company.id}
                                  checked={
                                    company.id == this.state.selectedCompany.id
                                  }
                                  onChange={() =>
                                    this.handleSelectedCompanyfromList(company)
                                  }
                                /> */}

                                  <span
                                    className={`${
                                      company.id ==
                                      this.state.selectedCompany.id
                                        ? "active-selected"
                                        : ""
                                    }`}
                                  >
                                    {company.companyName}
                                  </span>
                                </div>
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <DateRangePicker
                  ranges={predefinedRanges}
                  value={
                    this.state.selectedDate.from && this.state.selectedDate.to
                      ? this.state.dateRangeValue
                      : ""
                  }
                  className={`col-3 ${
                    !selectedReportType.type ? "disabled-link" : ""
                  }`}
                  placeholder="Date Range *"
                  disabledDate={combine(allowedMaxDays(180), afterToday())}
                  editable={false}
                  onSelect={this.handleSelect}
                  onOk={this.handleDateSelect}
                  onClean={this.handleValueClean}
                  onChange={this.handleOnChange}
                  placement="bottomEnd"
                />
                <div
                  className={`cinemas-section company-input-form m-0 col-3 ${
                    !selectedReportType.type ||
                    !selectedCompany.companyName ||
                    selectedCompany.companyName == "All Companies" ||
                    selectedReportType.type == "Executive reports"
                      ? "disabled-link"
                      : ""
                  }`}
                >
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle report-drop"
                      type="button"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="true"
                      aria-expanded="false"
                    >
                      {selectedTier.tiername ? selectedTier.tiername : "Tier"}
                    </button>
                    <div className="dummy">
                      <ul className="dropdown-menu report-tier-list">
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => this.handleAllTiers("All Tiers")}
                          >
                            <div
                              className={`form-check ps-0 ${
                                this.state.selectedTier.tiername == "All Tiers"
                                  ? "active-selected"
                                  : ""
                              }`}
                            >
                              All Tiers
                            </div>
                          </a>
                        </li>
                        {tiersList.map((tier) => {
                          return (
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  this.handleSelectedTierFromList(tier);
                                }}
                              >
                                <div className="form-check ps-0">
                                  {/* <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={tier.id}
                                  checked={tier.id == this.state.selectedTier.id}
                                  onChange={() =>
                                    this.handleSelectedTierFromList(tier)
                                  }
                                /> */}
                                  <span
                                    className={`${
                                      tier.id == this.state.selectedTier.id
                                        ? "active-selected"
                                        : ""
                                    }`}
                                  >
                                    {tier.tiername}
                                  </span>
                                </div>
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
              <div className="btnWrap flex-fill">
                <button
                  className={`btn btn-primary btn-small ${
                    !selectedReportType.type ||
                    !selectedCompany.companyName ||
                    (!selectedDate.from && !selectedDate.to)
                      ? "disabled-link"
                      : ""
                  }`}
                  onClick={this.handleApplyFilter}
                >
                  Apply
                </button>
              </div>
            </div>
            <div className="summery-line">
              {selectedReportType.type && (
                <span>
                  <b>{selectedReportType.type}</b>{" "}
                </span>
              )}
              {selectedCompany.companyName && (
                <span>
                  for <b>{selectedCompany.companyName}</b>{" "}
                </span>
              )}
              {selectedDate.from && selectedDate.to && (
                <span>
                  during{" "}
                  <b className="date">
                    {selectedDate.from} ~ {selectedDate.to}
                  </b>{" "}
                </span>
              )}
              {selectedTier.tiername && (
                <span>
                  for <b>{selectedTier.tiername} </b>{" "}
                </span>
              )}
            </div>
          </div>
        </div>

        {isTableVisible ? (
          <div className="reports-data">
            {selectedReportType.type == "User Reports" ? (
              <div className="dataValueTable">
                {userReportsArray[0].data.length > 0 ? (
                  <div className="user-reports-table">
                    <div className="table-responsive">
                      <table className="manage-table table table-striped reports-table">
                        <thead className="table-dark">
                          <tr>
                            <th>COMPANY NAME</th>
                            <th>NAME OF USER</th>
                            <th>EMAIL ADDRESS</th>
                            <th>TIER INFORMATION</th>
                            <th>
                              No of Transactions <br />
                              made based on the date <br />
                              range selected
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {userReportsArray[0].data.map((user) => {
                            return (
                              <tr>
                                <td className="font-gorita-medium">
                                  {user.companyName}
                                </td>
                                <td>{user.firstname + " " + user.lastname}</td>
                                <td>{user.email}</td>
                                <td>{user.tiername}</td>
                                <td>{user.bookingsCount}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    {userReportsArray.map((user) =>
                      user.totalPages > 1 ? (
                        <div className="pagination">
                          <div>
                            <span>Page</span>{" "}
                            <span className="page-count">
                              {user.currentPage}
                            </span>
                            of
                            <span className="total-page page-count">
                              {user.totalPages}
                            </span>
                          </div>
                          <div className="pagenation-arrow">
                            <span
                              className={`icon icon-prev ${
                                user.currentPage == 1 ? "disabled" : ""
                              }`}
                              onClick={this.goToPreviousUserPage}
                            ></span>{" "}
                            <span
                              className={`icon icon-next ${
                                user.totalPages == this.state.currentPage
                                  ? "disabled"
                                  : ""
                              }`}
                              onClick={this.goToNextUserPage}
                            ></span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    )}

                    <div className="btnWrap">
                      <button
                        className="btn btn-black btn-small ms-0 btn-download"
                        onClick={this.getUserReportsCsvData}
                      >
                        <span className="icon-download"></span>Download Now
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="text-center noData">No records found</div>
                )}
              </div>
            ) : selectedReportType.type == "Ticket Usage Reports" ? (
              <div className="data-value-table">
                {ticketUsageReports[0].data.length > 0 ? (
                  <div className="ticket-usage-reports-table">
                    <div className="table-responsive">
                      <table className="manage-table table table-striped reports-table">
                        <thead className="table-dark">
                          <tr>
                            <th>Name of User</th>
                            <th>Email address</th>
                            <th>Tier Information</th>
                            <th>Company Name</th>
                            <th>Booking ID</th>
                            <th>Transaction Date</th>
                            <th>Transaction ID</th>
                            <th>Name of the Film</th>
                            <th>Cinema Name or ID</th>
                            <th>Showdate and Time</th>
                          </tr>
                        </thead>

                        <tbody>
                          {ticketUsageReports[0].data.map((ticket) => {
                            return (
                              <tr>
                                <td>
                                  {ticket.firstname + " " + ticket.lastname}
                                </td>
                                <td>{ticket.email}</td>
                                <td>{ticket.tierName}</td>
                                <td>{ticket.companyName}</td>
                                <td>{ticket.posBookingId}</td>

                                <td>
                                  {moment(ticket.bookingDate).format(
                                    "DD MMM YYYY"
                                  )}
                                </td>
                                <td>
                                  {ticket.posTransactId || ticket.transactionId}
                                </td>
                                <td>{ticket.filmTitle}</td>
                                <td>{ticket.cinemaName}</td>
                                <td>
                                  {this.formatDate(ticket.showTime)},{" "}
                                  {this.formatTime(ticket.showTime)}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    {ticketUsageReports.map((ticket) =>
                      ticket.totalPages > 1 ? (
                        <div className="pagination">
                          <div>
                            <span>Page</span>{" "}
                            <span className="page-count">
                              {ticket.currentPage}
                            </span>
                            of
                            <span className="total-page page-count">
                              {ticket.totalPages}
                            </span>
                          </div>
                          <div className="pagenation-arrow">
                            <span
                              className={`icon icon-prev ${
                                ticket.currentPage == 1 ? "disabled" : ""
                              }`}
                              onClick={this.goToPreviousUserPage}
                            ></span>{" "}
                            <span
                              className={`icon icon-next ${
                                ticket.totalPages == this.state.currentPage
                                  ? "disabled"
                                  : ""
                              }`}
                              onClick={this.goToNextUserPage}
                            ></span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    )}

                    <div className="btnWrap">
                      <button
                        className="btn btn-black btn-small ms-0 btn-download"
                        onClick={this.getTicketUsageReportsCsvData}
                      >
                        <span className="icon-download"></span>Download Now
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="text-center noData">No records found</div>
                )}
              </div>
            ) : selectedReportType.type == "Executive reports" ? (
              <div className="data-value-table">
                {executiveReports.length > 0 ? (
                  <div className="executive-reports-table">
                    <div className="table-responsive">
                      <table className="manage-table table table-striped reports-table">
                        <thead className="table-dark">
                          <tr>
                            <th>Company Name</th>
                            <th>No. of Users enrolled till date</th>
                            <th>Total number of Transactions</th>
                            <th>Total Revenue earned</th>
                            <th>Total number of Tickets Purchased</th>
                            <th>Revenue on Tickets</th>
                            <th>Total number of F&B items Purchased</th>
                            <th>Revenue on F&B</th>
                            <th>Most Active Tier</th>
                          </tr>
                        </thead>

                        <tbody>
                          {executiveReports.map((executiveReport) => {
                            return (
                              <tr>
                                <td>{executiveReport.companyName}</td>
                                <td>{executiveReport.usersEnrolled}</td>
                                <td>{executiveReport.totalTransactions}</td>
                                <td>{executiveReport.totalRevenue}</td>
                                <td>{executiveReport.totalTicketsPurchased}</td>
                                <td>{executiveReport.revenueOnTickets}</td>
                                <td>
                                  {executiveReport.totalFnBItemsPurchased}
                                </td>
                                <td>{executiveReport.revenueOnFnBItems}</td>
                                <td>{executiveReport.activeTierName}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <div className="btnWrap">
                      <button
                        className="btn btn-black btn-small ms-0 btn-download"
                        onClick={this.getExecutiveReportsCsvData}
                      >
                        <span className="icon-download"></span>Download Now
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="text-center noData">No records found</div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        {isLoading ? (
          <div className="loader-div">
            {" "}
            <LoadingSpinner />{" "}
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}

export default function TempFunction() {
  return <Reports navigate={useNavigate()} />;
}
