import React, { Component } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { EDIT, WARNING_ICON } from "../../../../assets/index";
import * as API from "../../../../configuration/apiconfig";
import LoadingSpinner from "../../../LoadingSpinner";
import Heading from "../../Heading";

class EditCompany extends Component {
  constructor(props) {
    super(props);
    this.editLogoInput = React.createRef();
    this.state = {
      isLoading: false,
      selectedCompany: this.props.navigateState,
      isChecked: this.props.navigateState.status === 1 ? true : false,
      companyData: {
        companyName: "",
        arabicCompanyName: "",
        contactName: "",
        contactEmail: "",
        emailHandle: "",
        location: "",
        status: "",
        logo: "",
      },
      errors: {
        companyName: "",
        arabicCompanyName: "",
        contactName: "",
        contactEmail: "",
        emailHandle: "",
        location: "",
      },
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);

    this.setState({
      companyData: {
        id: this.state.selectedCompany.id,
        companyName: this.state.selectedCompany.companyName,
        arabicCompanyName: this.state.selectedCompany.altName,
        contactName: this.state.selectedCompany.contactName,
        contactEmail: this.state.selectedCompany.contactEmail,
        emailHandle: this.state.selectedCompany.emailHandle.toString(),
        location: this.state.selectedCompany.location,
        status: this.state.selectedCompany.status,
        logo: this.state.selectedCompany.logo,
      },
    });
  };

  validate = (name, value) => {
    const { companyData } = this.state;
    switch (name) {
      case "companyName":
        if (!value || value.trim() === "") {
          return "Please enter the company name.";
        } else {
          return "";
        }
      case "arabicCompanyName":
        if (!value || value.trim() === "") {
          return "Please enter the arabic company name.";
        } else {
          return "";
        }
      case "contactName":
        if (!value || value.trim() === "") {
          return "Please enter the contact name.";
        } else {
          return "";
        }
      case "contactEmail":
        if (!value) {
          return "Please enter the contact email address.";
        } else if (
          !value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
        ) {
          return "Please enter a valid contact email address";
        } else {
          return "";
        }
      case "emailHandle":
        if (!value) {
          return " Please enter the company email address.";
        }
        // else if (
        //   !value.match(/^@([a-zA-Z0-9-]+[.]){1,2}[a-zA-Z]{2,10}$/)
        // ) {
        //   return "Please enter a valid company email address";
        // }
        else {
          return "";
        }
      case "location":
        if (!value || value.trim() === "") {
          return "Please enter the location.";
        } else {
          return "";
        }
      default: {
        return "";
      }
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: this.validate(name, value),
      },
      companyData: {
        ...this.state.companyData,
        [name]: value,
      },
    });
  };

  handleChangeSwitch = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  handleEditCompanyLogo = (e) => {
    this.editLogoInput.current.click();
  };

  handleEditImageUpload = (e) => {
    let uploadedLogoFile = e.target.files[0];
    if (!uploadedLogoFile) {
      return;
    }
    this.setState({
      isLoading: true,
    });

    API.uploadImage("POST", uploadedLogoFile, "logo")
      .then((response) => {
        this.setState({
          isLoading: false,
          companyData: {
            ...this.state.companyData,
            logo: response.url,
          },
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  handleSubmit = async (e) => {
    const { companyData } = this.state;
    // e.preventDefault();
    let validationErrors = {};
    Object.keys(companyData).forEach((name) => {
      const error = this.validate(name, companyData[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      this.setState({ errors: validationErrors });
      return;
    }

    if (
      companyData.companyName &&
      companyData.arabicCompanyName &&
      companyData.contactName &&
      companyData.contactEmail &&
      companyData.emailHandle &&
      companyData.location
      // companyData.logo
    ) {
      const data = {
        id: companyData.id,
        altName: companyData.arabicCompanyName,
        companyName: companyData.companyName,
        contactName: companyData.contactName,
        contactEmail: companyData.contactEmail,
        emailHandle: companyData.emailHandle.split(",").map((i) => i.trim()),
        logo: companyData.logo ? companyData.logo : "",
        phone: "7382451005",
        location: companyData.location,
        status: this.state.isChecked ? 1 : 0,
      };
      this.setState({
        isLoading: true,
      });

      const responseData = await API.callEndpoint(
        "PATCH",
        "Bearer",
        "/api/companies",
        data
      ).then((response) => {
        Swal.fire({
          icon: "success",
          title: "Company updated successfully",
          showConfirmButton: true,
          confirmButtonColor: "#e51937",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.props.navigate("/manage-companies");
          }
        });
        return response.data;
      });
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { errors, isLoading, isChecked, selectedCompany, companyData } =
      this.state;
    return (
      <section className="addCompanyWrapper">
        <Heading title="Edit company" />

        <nav>
          <ul>
            <li>
              <a
                className="link-added"
                onClick={() => {
                  this.props.navigate("/dashboard");
                }}
              >
                Home
              </a>
            </li>
            <li>
              <a
                className="link-added"
                onClick={() => {
                  this.props.navigate("/manage-companies");
                }}
              >
                Manage Company
              </a>
            </li>
            <li>
              <a className="link-added">Edit Company</a>
            </li>
          </ul>
        </nav>

        <div className="company-input-form">
          <a
            className="back-link"
            onClick={() => {
              this.props.navigate("/manage-companies");
            }}
          >
            Back
          </a>
          <div className="row">
            <div className="col-5 mb-5">
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control ${
                    errors.companyName ? "input-error" : ""
                  }`}
                  id="companyname"
                  placeholder="Company Name"
                  name="companyName"
                  defaultValue={selectedCompany.companyName}
                  onChange={this.handleChange}
                />
                <label htmlFor="companyname">Company Name</label>
              </div>
              {errors.companyName && (
                <div className="error-text d-flex">
                  <img src={WARNING_ICON} alt="warning" className="me-1" />
                  <span>{errors.companyName}</span>
                </div>
              )}
            </div>

            <div className="col-5 mb-5">
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control ${
                    errors.arabicCompanyName ? "input-error" : ""
                  }`}
                  id="arabicCompanyName"
                  placeholder="Company Name (Arabic) *"
                  name="arabicCompanyName"
                  defaultValue={selectedCompany.altName}
                  onChange={this.handleChange}
                />
                <label htmlFor="arabicCompanyName">
                  Company Name (Arabic) *
                </label>
              </div>
              {errors.arabicCompanyName && (
                <div className="error-text d-flex">
                  <img src={WARNING_ICON} alt="warning" className="me-1" />
                  <span>{errors.arabicCompanyName}</span>
                </div>
              )}
            </div>

            <div className="col-5 mb-5">
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control ${
                    errors.location ? "input-error" : ""
                  }`}
                  id="location"
                  placeholder="Location"
                  name="location"
                  defaultValue={selectedCompany.location}
                  onChange={this.handleChange}
                />
                <label htmlFor="location">Location</label>
              </div>
              {errors.location && (
                <div className="error-text d-flex">
                  <img src={WARNING_ICON} alt="warning" className="me-1" />
                  <span>{errors.location}</span>
                </div>
              )}
            </div>

            <div className="col-5 mb-5">
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control ${
                    errors.contactName ? "input-error" : ""
                  }`}
                  id="contactname"
                  placeholder="Contact Name"
                  name="contactName"
                  defaultValue={selectedCompany.contactName}
                  onChange={this.handleChange}
                />
                <label htmlFor="contactname">Contact Name</label>
              </div>
              {errors.contactName && (
                <div className="error-text d-flex">
                  <img src={WARNING_ICON} alt="warning" className="me-1" />
                  <span>{errors.contactName}</span>
                </div>
              )}
            </div>

            <div className="col-5 mb-5">
              <div className="form-floating">
                <input
                  type="email"
                  className={`form-control ${
                    errors.contactEmail ? "input-error" : ""
                  }`}
                  id="contactemail"
                  placeholder="Contact Email address"
                  name="contactEmail"
                  defaultValue={selectedCompany.contactEmail}
                  onChange={this.handleChange}
                />
                <label htmlFor="contactemail">Contact Email address</label>
              </div>
              {errors.contactEmail && (
                <div className="error-text d-flex">
                  <img src={WARNING_ICON} alt="warning" className="me-1" />
                  <span>{errors.contactEmail}</span>
                </div>
              )}
            </div>

            <div className="col-5 mb-5">
              <div className="form-floating">
                <input
                  type="email"
                  className={`form-control ${
                    errors.emailHandle ? "input-error" : ""
                  }`}
                  id="emailHandle"
                  placeholder="Company Email Domain"
                  name="emailHandle"
                  defaultValue={selectedCompany.emailHandle}
                  onChange={this.handleChange}
                />
                <label htmlFor="emailHandle">Company Email Domain</label>
              </div>
              {errors.emailHandle && (
                <div className="error-text d-flex">
                  <img src={WARNING_ICON} alt="warning" className="me-1" />
                  <span>{errors.emailHandle}</span>
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-5 mb-5">
              <div className="form-check form-switch">
                <label className="form-check-label">Status</label>
                <div className="toggle-activate">
                  <label
                    className="form-label"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    Inactive
                  </label>
                  <input
                    className={`form-check-input ${isChecked ? "checked" : ""}`}
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    onChange={this.handleChangeSwitch}
                    checked={isChecked}
                  />
                  <label
                    className="form-label"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    Active
                  </label>
                </div>
              </div>
            </div>
            <div className="col-5 mb-5">
              <label className="form-label d-flex align-items-center gap-2">
                Upload company logo
                <a
                  className="edit-logo-link"
                  onClick={() => this.handleEditCompanyLogo}
                >
                  <img
                    src={EDIT}
                    alt="edit-uploaded-logo"
                    className="edit-logo"
                  />
                </a>
                <input
                  type="file"
                  className="d-none"
                  id="uploadlogo"
                  ref={this.editLogoInput}
                  accept="image/png, image/gif, image/jpeg, image/jpg, image/svg"
                  onChange={this.handleEditImageUpload}
                />
              </label>

              <div className="upload logo">
                {companyData.logo && (
                  <img src={companyData.logo} className="upload-logo" />
                )}
              </div>
            </div>
          </div>

          <div className="btnWrap">
            <button
              className="btn btn-primary btn-small"
              onClick={this.handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>

        {isLoading ? (
          <div className="loader-div">
            {" "}
            <LoadingSpinner />{" "}
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}

export default function TempFunction(props) {
  const { state } = useLocation();
  return <EditCompany navigate={useNavigate()} navigateState={state} />;
}
