import axios from "axios";
import { Buffer } from "buffer";
var accessTokenExpiry = 300;
var methodType, authorisationType, URL, data;

export async function callEndpoint(methodType, authorisationType, URL, data) {
  methodType = methodType;
  authorisationType = authorisationType;
  URL = URL;
  data = data;
  if (authorisationType === "Bearer") {
    var accessToken = getCookie("accessToken");
    if (accessToken == null) {
      return new Promise((resolve, reject) => {
        getAccessToken("", "", true).then((response) => {
          callEndpoint(methodType, authorisationType, URL, data)
            .then((response) => {
              resolve(response);
            })
            .catch(reject);
        });
      });
    } else {
      return new Promise((resolve, reject) => {
        // var dataversion = getCookie("ticketBookingLang");
        var dataversion = "en";
        if (dataversion == "en") {
          dataversion = "en-US";
        } else if (dataversion == "ar") {
          dataversion = "ar-SA";
        } else {
          dataversion = "en-US";
        }
        axios({
          url: process.env.REACT_APP_BASEURL + URL,
          method: methodType,
          headers: {
            Authorization: "Bearer " + getCookie("accessToken"),
            // dataversion: dataversion,
            // appplatform: "WEBSITE",
            // appversion: "1.0.0",
            // usesessioncutoff: "fnb",
          },
          data: data,
        })
          .then((response) => {
            resolve(response);
          })
          .catch(async (ex) => {
            if (ex.response.data.message != undefined) {
              if (ex.response.data.message.toLowerCase() === "unauthorized") {
                await getRefreshToken().then(async (response) => {
                  await callEndpoint(methodType, authorisationType, URL, data)
                    .then((response) => {
                      resolve(response);
                    })
                    .catch(reject);
                });
              } else {
                reject({ error: ex.response.data.message });
              }
            } else {
              reject({ error: ex.response.data.message });
            }
          });
      });
    }
  } else if (authorisationType === "Basic") {
    var headerObject = new Object();
    headerObject.Authorization =
      "Basic " +
      new Buffer.from(
        process.env.REACT_APP_USERNAME + ":" + process.env.REACT_APP_PASSWORD
      ).toString("base64");
    headerObject.appplatform = "WEBSITE";
    headerObject.appversion = "1.0.0";
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.REACT_APP_BASEURL + "" + URL,
        method: methodType,
        headers: headerObject,
        data: data,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((ex) => {
          reject({ error: ex.response.data.message });
        });
    });
  }
}

export async function getAccessToken(
  username,
  password,
  keepMeSignedIn = true,
  getUserInfo = true
) {
  var data = new Object();
  data.accessTokenExpiry = accessTokenExpiry;
  data.getUserInfo = getUserInfo;
  data.keepMeSignedIn = true;
  return new Promise((resolve, reject) => {
    axios({
      // url: process.env.REACT_APP_BASEURL + "user/v1/token",
      url: process.env.REACT_APP_BASEURL + "/users/login",
      method: "POST",
      headers: {
        signinplatform: "adminpanel",
      },
      data: data,
      auth: {
        // username: process.env.REACT_APP_USERNAME,
        // password: process.env.REACT_APP_PASSWORD,
        username: username,
        password: password,
      },
    })
      .then((response) => {
        document.cookie = "accessToken=" + response.data.accessToken;
        document.cookie = "refreshToken=" + response.data.refreshToken;
        document.cookie = "email=" + username;
        // document.cookie = "password=" + password;
        resolve(response);
      })
      .catch((ex) => {
        reject({ error: ex });
      });
  });
}

async function getRefreshToken() {
  var headerDetails =
    "Basic " +
    Buffer.from(
      process.env.REACT_APP_USERNAME + ":" + process.env.REACT_APP_PASSWORD
    ).toString("base64");
  var data = new Object();
  data.refreshToken = getCookie("refreshToken");
  data.keepMeSignedIn = true;
  return new Promise(async (resolve, reject) => {
    axios({
      //url: "https://apidevshowplaceicon.influx.co.in/api/v1/users/refreshToken",
      // url: process.env.REACT_APP_BASEURL + "user/v1/token/refresh",
      url: process.env.REACT_APP_BASEURL + "/users/refreshToken",
      method: "POST",
      auth: {
        username: process.env.REACT_APP_USERNAME,
        password: process.env.REACT_APP_PASSWORD,
      },
      data: data,
    })
      .then((response) => {
        document.cookie = "accessToken=" + response.data.accessToken;
        document.cookie = "refreshToken=" + response.data.refreshToken;
        resolve(response);
      })
      .catch(async (ex) => {
        if (axios.isCancel(ex)) {
          reject({ Cancel: "" });
        } else if (
          ex.response.data.code != 200 ||
          ex.response.data.message.toLowerCase() == "token invalid"
        ) {
          await getAccessToken(
            process.env.REACT_APP_USERNAME,
            process.env.REACT_APP_PASSWORD
          ).then((response) => {
            callEndpoint(methodType, authorisationType, URL, data)
              .then((response) => {
                resolve(response);
              })
              .catch(reject);
          });
        }
      });
  });
}

export function getValueFromCookie(cookieName) {
  return getCookie(cookieName);
}

function getCookie(name) {
  var cookieArr = document.cookie.split(";");
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}

export function deletCookie(name) {
  document.cookie = `${name}= ; expires= Thu Jan 1970 00:00:00 GMT`;
}

export async function uploadImage(method, file, type) {
  var formData = new FormData();
  formData.append("file", file);
  formData.append("type", type);
  const methodType = method;
  const authorisationType = "Bearer";
  const url = "/api/fileupload";
  return new Promise((resolve, reject) => {
    axios({
      url: process.env.REACT_APP_BASEURL + url,
      method: method,
      headers: {
        Authorization: "Bearer " + getCookie("accessToken"),
        dataversion: "en-US",
        appplatform: "WEBSITE",
        appversion: "1.0.0",
        "Content-Type": "application/form-data",
      },
      data: formData,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (ex) => {
        if (ex.response.data.message.toLowerCase() === "unauthorized") {
          await getRefreshToken().then(async (response) => {
            await callEndpoint(method, authorisationType, url, formData)
              .then((response) => {
                resolve(response.data);
              })
              .catch(reject);
          });
        } else {
          reject({ error: ex.response.data.message });
        }
      });
  });
}

export async function uploadFile(method, file, id) {
  var formData = new FormData();
  formData.append("file", file);
  const methodType = method;
  const authorisationType = "Bearer";
  const url = `/api/companies/${id}/bulkcreate`;
  return new Promise((resolve, reject) => {
    axios({
      url: process.env.REACT_APP_BASEURL + url,
      method: method,
      headers: {
        Authorization: "Bearer " + getCookie("accessToken"),
        dataversion: "en-US",
        appplatform: "WEBSITE",
        appversion: "1.0.0",
        "Content-Type": "application/form-data",
      },
      data: formData,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (ex) => {
        if (ex.response.data.message.toLowerCase() === "unauthorized") {
          await getRefreshToken().then(async (response) => {
            await callEndpoint(methodType, authorisationType, url, formData)
              .then((response) => {
                resolve(response);
              })
              .catch(reject);
          });
        } else {
          reject({ error: ex.response.data.message });
        }
      });
  });
}
