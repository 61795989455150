import React, { Component } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import * as API from "../../../../configuration/apiconfig";
import Heading from "../../Heading";
import { ERRORS_ICON, WARNING_ICON } from "../../../../assets/index";
import LoadingSpinner from "../../../LoadingSpinner";
import Sample from "../sample.csv";
import { CSVLink, CSVDownload } from "react-csv";

class AddUserInBulk extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.state = {
      companyId: this.props.navigateState.id,
      isLoading: false,
      file: "",
      uploadedFileName: "",
      fileUploadError: "",
      bulkcreateStatus: 0,
      tiers: this.props.navigateState.tiers,
      emailHandle: this.props.navigateState.emailHandle,
      csvData: [],
      sampleDetails: [],
      duplicateUsersArray: [],
    };
  }
  componentDidMount() {
    var csvData = [["firstname", "lastname", "email", "phone", "tiername"]]; //first row is already populated becuase it is the headers row
    if (this.props.navigateState.tiers) {
      this.props.navigateState.tiers.map((tierDetails) => {
        var eachRow = [];
        eachRow.push(this.randomGenerator(3, "upper"));
        eachRow.push(this.randomGenerator(1, "upper"));
        eachRow.push(
          this.randomGenerator(3, "lower") +
            "@" +
            this.state.emailHandle[this.generateRandomNumber()]
        );
        eachRow.push(this.randomGenerator(10, "number"));
        eachRow.push(tierDetails.tiername);
        csvData.push(eachRow);
      });
    }
    this.setState(
      {
        csvData,
      },
      () => {
        console.log(this.state.csvData);
      }
    );
    if (this.props.navigateState.duplicateUsersList) {
      this.setState({
        duplicateUsersArray: this.props.navigateState.duplicateUsersList,
      });
    }
  }

  randomGenerator = (length, charactertype) => {
    var result = "",
      characters;
    var charactersUpper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var charactersLower = "abcdefghijklmnopqrstuvwxyz";
    var charactersNumber = "0123456789";

    if (charactertype == "upper") {
      characters = charactersUpper;
    } else if (charactertype == "lower") {
      characters = charactersLower;
    } else if (charactertype == "number") {
      characters = charactersNumber;
    }

    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  generateRandomNumber(
    min = 0,
    max = this.state.emailHandle == undefined
      ? 1
      : this.state.emailHandle.length
  ) {
    let difference = max - min;
    let rand = Math.random();
    rand = Math.floor(rand * difference);
    rand = rand + min;
    return rand;
  }

  getUser = async () => {
    this.setState({
      isLoading: true,
    });
    const responseData = await API.callEndpoint(
      "GET",
      "Bearer",
      `/api/users?page=2&companyid=${this.state.companyId}`
    ).then((response) => {
      return response.data;
    });
    const tierData = await API.callEndpoint(
      "GET",
      "Bearer",
      `/api/companies/${this.props.navigateState.id}/tiers`
    ).then((response) => {
      return response.data;
    });
    this.setState({
      tiersList: tierData,
      userList: responseData,
      isLoading: false,
    });
  };

  handleFileUpload = (e) => {
    if (e.target.files.length > 0) {
      this.setState({
        duplicateUsersArray: [],
      });
      let filename = e.target.files[0].name;
      this.setState({
        uploadedFileName: filename,
        file: e.target.files[0],
        fileUploadError: "",
      });
    }
  };

  handleSubmit = async () => {
    if (this.state.uploadedFileName === "") {
      this.setState({
        fileUploadError: "Please upload the CSV file",
      });
    } else {
      const responseData = API.uploadFile(
        "POST",
        this.state.file,
        this.state.companyId
      ).then((response) => {
        if (response.invalidRecords.length === 0) {
          Swal.fire({
            title: "File has been uploaded successfully",
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then((result) => {
            document.cookie = `BulkUserProgressBarVisibility_${this.state.companyId}=true; path=/`;
            if (result.isConfirmed) {
              this.props.navigate("/manage-users/users", {
                state: {
                  ...this.props.navigateState,
                  uploadedFile: this.state.file,
                },
              });
            }
          });
        } else {
          const invalidRecords = response.invalidRecords.toString();
          const invalidRecordsMessage =
            response.invalidRecords.length === 1
              ? `There is an error on line <span>${invalidRecords}</span> in the csv file`
              : `There are errors on lines <span>${invalidRecords}</span> in the csv file`;

          Swal.fire({
            icon: "error",
            title: invalidRecordsMessage,
            showConfirmButton: true,
            confirmButtonColor: "#e51937",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              this.setState({
                uploadedFileName: "",
                file: "",
              });
            }
          });
        }
      });
    }
  };

  render() {
    const { uploadedFileName, fileUploadError, isLoading, file } = this.state;

    return (
      <section className="addCompanyWrapper">
        <Heading title="Adding User in Bulk" />

        <nav>
          <ul>
            <li>
              <a
                className="link-added"
                onClick={() => {
                  this.props.navigate("/dashboard");
                }}
              >
                Home
              </a>
            </li>
            <li>
              <a
                className="link link-added"
                onClick={() => {
                  this.props.navigate("/manage-users");
                }}
              >
                User Management
              </a>
            </li>
            <li>
              <a
                className="link link-added"
                onClick={() => {
                  this.props.navigate("/manage-users/users", {
                    state: this.props.navigateState,
                  });
                }}
              >
                {this.props.navigateState.companyName}
              </a>
            </li>
            <li>
              <a className="link link-added">Add User in Bulk</a>
            </li>
          </ul>
        </nav>
        <div className="row">
          <div className="col-12">
            <a
              href="javascript:;"
              className="back-link"
              onClick={() => {
                this.props.navigate("/manage-users/users", {
                  state: this.props.navigateState,
                });
              }}
            >
              Back
            </a>
          </div>
        </div>
        <div className="row company-input-form my-0">
          <div className="col-6">
            <div className="add-step m-0">
              <div className="instruction">
                Instruction to add users in bulk
              </div>
              <ul>
                <li>
                  Download the CSV file from{" "}
                  <CSVLink
                    data={this.state.csvData}
                    filename={"Sample.csv"}
                    className="a-link"
                    id="downlink"
                    enclosingCharacter={""}
                  >
                    here
                  </CSVLink>
                </li>

                <li>
                  The prepopulated data will have the tiers configured for your
                  company.
                </li>
                <li>
                  Ensure the users you add to the file are assigned to tiers
                  available in the sheet only
                </li>
                <li>Cross-check the file before uploading</li>
              </ul>
            </div>
          </div>

          <div className="col-6">
            <div className="mb-4">
              <label className="form-label">Upload the CSV file*</label>

              {uploadedFileName ? (
                <div className="upload filename">{uploadedFileName}</div>
              ) : (
                <div
                  className={`upload ${fileUploadError ? "input-error" : ""}`}
                >
                  Upload the CSV file
                  <input
                    type="file"
                    className="form-control"
                    id="uploadFile"
                    onChange={this.handleFileUpload}
                  />
                </div>
              )}
              {fileUploadError && (
                <div className="error-text d-flex">
                  <img src={WARNING_ICON} alt="warning" className="me-1" />
                  <span>{fileUploadError}</span>
                </div>
              )}
            </div>

            <div className="btnWrap">
              <a
                className="btn btn-primary btn-small"
                onClick={this.handleSubmit}
              >
                Submit
              </a>
            </div>
          </div>
        </div>

        {this.state.duplicateUsersArray.length > 0 ? (
          <div className="row ">
            <div className="col-6">
              <div className="duplicate-users-list-card">
                <div className="card">
                  <div className="header">
                    <img src={ERRORS_ICON} alt="error-icon" />
                    <span> Error! </span>
                  </div>
                  <div className="card-body">
                    <p className="card-title">
                      The uploaded file has duplicate email addresses. Find
                      below the duplicate email address:
                    </p>
                    <div className="duplicate-user-body">
                      {this.state.duplicateUsersArray.map((duplicateUser) => {
                        return (
                          <p className="duplicate-users-email">
                            {duplicateUser}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {isLoading ? (
          <div className="loader-div">
            {" "}
            <LoadingSpinner />{" "}
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}

export default function TempFunction(props) {
  const { state } = useLocation();
  return <AddUserInBulk navigate={useNavigate()} navigateState={state} />;
}
