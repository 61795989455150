import React, { Component } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { INFO_ICON, WARNING_ICON } from "../../../../assets/index";
import * as API from "../../../../configuration/apiconfig";
import LoadingSpinner from "../../../LoadingSpinner";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const initialState = {
  name: "",
  arabicDealName: "",
  code: "",
  description: "",
  arabicDescription: "",
  offerDays: [],
};

export default class AddDeal extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      isSelected: false,
      isLoading: false,
      deal: initialState,
      errors: {
        name: "",
        arabicDealName: "",
        code: "",
        description: "",
        arabicDescription: "",
      },
      availabilityrulesError: "",
      weekday: [
        { id: 1, day: "Sun", isSelected: false },
        { id: 2, day: "Mon", isSelected: false },
        { id: 3, day: "Tue", isSelected: false },
        { id: 4, day: "Wed", isSelected: false },
        { id: 5, day: "Thu", isSelected: false },
        { id: 6, day: "Fri", isSelected: false },
        { id: 7, day: "Sat", isSelected: false },
      ],
    };
  }

  validate = (name, value) => {
    switch (name) {
      case "name":
        if (!value || value.trim() === "") {
          return "Please enter deal name";
        } else {
          return "";
        }
      case "arabicDealName":
        if (!value || value.trim() === "") {
          return "Please enter arabic deal name";
        } else {
          return "";
        }
      case "description":
        if (!value || value.trim() === "") {
          return "Please enter description";
        } else {
          return "";
        }
      case "arabicDescription":
        if (!value || value.trim() === "") {
          return "Please enter arabic description";
        } else {
          return "";
        }
      case "code":
        if (!value || value.trim() === "") {
          if (this.props.dealType === "ticket") {
            return "Please enter head office ticket type code";
          } else {
            return "Please enter F&B Promo Code";
          }
        } else {
          return "";
        }
      default: {
        return "";
      }
    }
  };

  handleValueChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: this.validate(name, value),
      },
      deal: {
        ...this.state.deal,
        [name]: value,
      },
    });
  };

  handleDay = (row) => {
    this.state.weekday.find((day) => day.id === row.id).isSelected =
      !row.isSelected;
    const selectedDay = this.state.weekday.find((day) => day.id === row.id);

    this.setState({
      deal: {
        ...this.state.deal,
        offerDays: [...this.state.deal.offerDays, selectedDay.id],
      },
    });

    if (this.state.deal.offerDays.includes(selectedDay.id)) {
      const index = this.state.deal.offerDays.findIndex((num) => {
        return num === selectedDay.id;
      });

      this.state.deal.offerDays.splice(index, 1);
      this.setState({
        deal: {
          ...this.state.deal,
          offerDays: [...this.state.deal.offerDays],
        },
      });
    }

    if (this.state.deal.offerDays.length === 0 && row.isSelected === false) {
      this.setState({
        availabilityrulesError: "Please select deal date",
      });
    } else {
      this.setState({
        availabilityrulesError: "",
      });
    }
  };

  handleModalClose = () => {
    this.setState({
      errors: {},
      availabilityrulesError: "",
      deal: initialState,
    });
    this.props.handleClose();
  };

  handleAddDeal = async (e) => {
    const { deal } = this.state;

    e.preventDefault();
    let validationErrors = {};

    const isNotSelectedAnyDate = this.state.weekday.every(
      (item) => item.isSelected === false
    );

    if (deal.offerDays.length === 0) {
      this.setState({
        availabilityrulesError: "Please select deal date",
      });
    }

    Object.keys(deal).forEach((name) => {
      const error = this.validate(name, deal[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      this.setState({ errors: validationErrors });
      return;
    }

    if (
      (deal.name && deal.arabicDealName,
      deal.description &&
        deal.arabicDescription &&
        deal.code &&
        deal.offerDays.length > 0)
    ) {
      this.setState({
        isLoading: true,
      });

      const offerItemStringToArray = this.state.deal.code.split(",");
      const offerItemArrayToObj = offerItemStringToArray.map((item) => ({
        itemType: this.props.dealType,
        itemCode: item,
      }));

      const getDealsApiResponse = await API.callEndpoint(
        "POST",
        "Bearer",
        `/api/companies/${this.props.companyId}/tiers/${this.props.tierId}/deals`,
        {
          name: this.state.deal.name,
          altName: this.state.deal.arabicDealName,
          dealType: this.props.dealType,
          description: this.state.deal.description,
          arabicDescription: this.state.deal.arabicDescription,
          availabilityrules: {
            offerDays: this.state.deal.offerDays,
          },
          offeritems: offerItemArrayToObj,
        }
      ).then((response) => {
        this.setState({
          isLoading: false,
        });
        Swal.fire({
          icon: "success",
          title: "Deal created successfully",
          showConfirmButton: true,
          confirmButtonColor: "#e51937",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.props.handleClose();
            this.setState({
              deal: initialState,
            });
            this.state.weekday.map((day) => (day.isSelected = false));
          }
        });
        return response.data;
      });
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { deal, weekday, isLoading, errors, availabilityrulesError } =
      this.state;

    return (
      <div>
        {this.props.showModal && (
          <div className="add-deal-modal">
            <div className="overlay"></div>
            <div
              className={`modal fade deal-modal ${
                this.props.showModal ? "show" : "close-modal"
              }`}
              id="addModal"
              tabindex="-1"
              aria-labelledby="addDealModal"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title" id="addDealModal">
                      Add{" "}
                      {this.props.dealType === "ticket" ? "Ticket" : "F & B"}{" "}
                      Deal
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={this.handleModalClose}
                    ></button>
                  </div>
                  <div className="modal-body company-input-form">
                    <div className="row">
                      <div className="col-6 d-flex flex-column justify-content-between">
                        <div className="field">
                          <label className="form-label">Deal Name *</label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            value={deal.name}
                            placeholder="Deal Name"
                            className={`form-control ${
                              errors.name ? "input-error" : ""
                            }`}
                            onChange={this.handleValueChange}
                          />
                          {errors.name && (
                            <div className="error-text d-flex">
                              <img
                                src={WARNING_ICON}
                                alt="warning"
                                className="me-1"
                              />
                              <span>{errors.name}</span>
                            </div>
                          )}
                        </div>
                        <div className="field">
                          <label className="form-label">
                            Deal Name (Arabic)*
                          </label>
                          <input
                            type="text"
                            id="arabicDealName"
                            name="arabicDealName"
                            value={deal.arabicDealName}
                            placeholder="Deal Name (Arabic)"
                            className={`form-control ${
                              errors.arabicDealName ? "input-error" : ""
                            }`}
                            onChange={this.handleValueChange}
                          />
                          {errors.arabicDealName && (
                            <div className="error-text d-flex">
                              <img
                                src={WARNING_ICON}
                                alt="warning"
                                className="me-1"
                              />
                              <span>{errors.arabicDealName}</span>
                            </div>
                          )}
                        </div>
                        <div className="field">
                          <label className="form-label">
                            {this.props.dealType === "ticket"
                              ? "Head Office Code *"
                              : "F&B Promo Code *"}
                            <img
                              src={INFO_ICON}
                              alt="info"
                              className="info-icon"
                              id="app-title"
                            />
                            <ReactTooltip
                              anchorId="app-title"
                              place="right"
                              content={
                                this.props.dealType === "ticket"
                                  ? "Kindly enter Head Office Code configured for corporate users in Vista"
                                  : " Kindly enter F&B Promo Code configured for corporate users in Vista"
                              }
                            />
                          </label>

                          <input
                            type="text"
                            id="code"
                            name="code"
                            value={deal.code}
                            placeholder="Enter the Code"
                            className={`form-control ${
                              errors.code ? "input-error" : ""
                            }`}
                            onChange={this.handleValueChange}
                          />
                          {errors.code && (
                            <div className="error-text d-flex">
                              <img
                                src={WARNING_ICON}
                                alt="warning"
                                className="me-1"
                              />
                              <span>{errors.code}</span>
                            </div>
                          )}
                        </div>
                        <div className="field">
                          <label className="form-label">Availability *</label>
                          <div className="day-badges">
                            {weekday.map((week) => (
                              <div
                                id={week.id}
                                className={`badge ${
                                  week.isSelected ? "selected-day" : ""
                                }`}
                                onClick={() => this.handleDay(week)}
                              >
                                {week.day}
                              </div>
                            ))}
                          </div>
                          {availabilityrulesError && (
                            <div className="error-text d-flex">
                              <img
                                src={WARNING_ICON}
                                alt="warning"
                                className="me-1"
                              />
                              <span>{availabilityrulesError}</span>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="field">
                          <label className="form-label">
                            Description (English) *
                          </label>
                          <textarea
                            className={`form-control textarea ${
                              errors.description ? "input-error" : ""
                            }`}
                            placeholder="Text here..."
                            id="floatingTextarea2"
                            rows="3"
                            name="description"
                            value={deal.description}
                            onChange={this.handleValueChange}
                          ></textarea>
                          {errors.description && (
                            <div className="error-text d-flex">
                              <img
                                src={WARNING_ICON}
                                alt="warning"
                                className="me-1"
                              />
                              <span>{errors.description}</span>
                            </div>
                          )}
                        </div>
                        <div className="field">
                          <label className="form-label">
                            Description (Arabic) *
                          </label>
                          <textarea
                            className={`form-control textarea ${
                              errors.arabicDescription ? "input-error" : ""
                            }`}
                            placeholder="Text here..."
                            id="arabicDescription"
                            rows="3"
                            name="arabicDescription"
                            value={deal.arabicDescription}
                            onChange={this.handleValueChange}
                          ></textarea>
                          {errors.arabicDescription && (
                            <div className="error-text d-flex">
                              <img
                                src={WARNING_ICON}
                                alt="warning"
                                className="me-1"
                              />
                              <span>{errors.arabicDescription}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="btnWrap">
                      <button
                        className="btn btn-black btn-small ms-0"
                        onClick={this.handleAddDeal}
                      >
                        <span className="icon-add"></span>Add Deal
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isLoading ? (
          <div className="loader-div">
            {" "}
            <LoadingSpinner />{" "}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
