import React, { Component } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import * as API from "../../../../configuration/apiconfig";
import Heading from "../../Heading";
import LoadingSpinner from "../../../LoadingSpinner";
import { WARNING_ICON } from "../../../../assets/index";

class EditTier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tiername: "",
      isLoading: false,
      selectedTier: this.props.navigateState.tier,
      errors: {
        tiername: "",
      },
    };
  }

  componentDidMount = () => {
    this.setState({
      tiername: this.state.selectedTier.tiername,
    });
  };

  handleChange = (e) => {
    this.setState({
      tiername: e.target.value.replace(/[^\w\s]/gi, ""),
    });
    if (!e.target.value) {
      this.setState({
        errors: {
          tiername: "Please enter the tier name.",
        },
      });
    } else {
      this.setState({
        errors: {
          tiername: "",
        },
      });
    }
  };

  handleSubmit = async (e) => {
    if (this.state.tiername === "") {
      this.setState({
        errors: {
          tiername: "Please enter the tier name.",
        },
      });
    } else {
      this.setState({
        errors: {},
      });
    }

    if (this.state.tiername) {
      this.setState({
        isLoading: true,
      });
      const responseData = await API.callEndpoint(
        "PATCH",
        "Bearer",
        `/api/companies/${this.props.navigateState.company.id}/tiers`,
        {
          id: this.state.selectedTier.id,
          tiername: this.state.tiername,
        }
      )
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Tier updated successfully",
            showConfirmButton: true,
            confirmButtonColor: "#e51937",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              this.props.navigate("/manage-tiers/tiers", {
                state: this.props.navigateState.company,
              });
            }
          });
          return response.data;
        })
        .catch((a) => {
          const errorMessage = a.error;
          this.setState({
            isLoading: true,
          });
          Swal.fire({
            icon: "error",
            title: errorMessage,
          });
        });
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { errors, isLoading, selectedTier } = this.state;

    return (
      <section className="addCompanyWrapper">
        <Heading title="Edit Tier" />

        <nav>
          <ul>
            <li>
              <a
                className="link-added"
                onClick={() => {
                  this.props.navigate("/dashboard");
                }}
              >
                Home
              </a>
            </li>
            <li>
              <a
                className="link link-added"
                onClick={() => {
                  this.props.navigate("/manage-tiers");
                }}
              >
                Manage Tiers
              </a>
            </li>
            <li>
              <a
                className="link link-added"
                onClick={() => {
                  this.props.navigate("/manage-tiers/tiers", {
                    state: this.props.navigateState.company,
                  });
                }}
              >
                {this.props.navigateState.company.companyName}
              </a>
            </li>
            <li>
              <a className="link link-added">Add Tier</a>
            </li>
          </ul>
        </nav>

        <div className="company-input-form">
          <a
            className="back-link"
            onClick={() => {
              this.props.navigate("/manage-tiers/tiers", {
                state: this.props.navigateState.company,
              });
            }}
          >
            Back
          </a>
          <div className="row">
            <div className="col-5 mb-5">
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control ${
                    errors.tiername ? "input-error" : ""
                  }`}
                  id="tiername"
                  placeholder="Tier Name"
                  name="tiername"
                  defaultValue={selectedTier.tiername}
                  value={this.state.tiername}
                  onChange={this.handleChange}
                />
                <label htmlFor="tiername">Tier Name</label>
              </div>
              {errors.tiername && (
                <div className="error-text d-flex">
                  <img src={WARNING_ICON} alt="warning" className="me-1" />
                  <span>{errors.tiername}</span>
                </div>
              )}
            </div>

            <div className="btnWrap">
              <button
                className="btn btn-primary btn-small"
                onClick={this.handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="loader-div">
            {" "}
            <LoadingSpinner />{" "}
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}

export default function TempFunction(props) {
  const { state } = useLocation();
  return <EditTier navigate={useNavigate()} navigateState={state} />;
}
